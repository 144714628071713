export const FREE_EMAIL_PROVIDERS = {
    '1033edge.com': false,
    '11mail.com': false,
    '123.com': false,
    '123box.net': false,
    '123india.com': false,
    '123mail.cl': false,
    '123qwe.co.uk': false,
    '126.com': false,
    '150ml.com': false,
    '15meg4free.com': false,
    '163.com': false,
    '1coolplace.com': false,
    '1freeemail.com': false,
    '1funplace.com': false,
    '1internetdrive.com': false,
    '1mail.net': false,
    '1me.net': false,
    '1mum.com': false,
    '1musicrow.com': false,
    '1netdrive.com': false,
    '1nsyncfan.com': false,
    '1under.com': false,
    '1webave.com': false,
    '1webhighway.com': false,
    '212.com': false,
    '24horas.com': false,
    '2911.net': false,
    '2bmail.co.uk': false,
    '2d2i.com': false,
    '2die4.com': false,
    '3000.it': false,
    '321media.com': false,
    '37.com': false,
    '3ammagazine.com': false,
    '3dmail.com': false,
    '3email.com': false,
    '3xl.net': false,
    '444.net': false,
    '4email.com': false,
    '4email.net': false,
    '4mg.com': false,
    '4newyork.com': false,
    '4x4man.com': false,
    '5iron.com': false,
    '5star.com': false,
    '88.am': false,
    '8848.net': false,
    '888.nu': false,
    '97rock.com': false,
    'aaamail.zzn.com': false,
    'aamail.net': false,
    'aaronkwok.net': false,
    'abbeyroadlondon.co.uk': false,
    'abcflash.net': false,
    'abdulnour.com': false,
    'aberystwyth.com': false,
    'abolition-now.com': false,
    'about.com': false,
    'academycougars.com': false,
    'acceso.or.cr': false,
    'access4less.net': false,
    'accessgcc.com': false,
    'ace-of-base.com': false,
    'acmecity.com': false,
    'acmemail.net': false,
    'acninc.net': false,
    'adelphia.net': false,
    'adexec.com': false,
    'adfarrow.com': false,
    'adios.net': false,
    'ados.fr': false,
    'advalvas.be': false,
    'aeiou.pt': false,
    'aemail4u.com': false,
    'aeneasmail.com': false,
    'afreeinternet.com': false,
    'africamail.com': false,
    'agoodmail.com': false,
    'ahaa.dk': false,
    'aichi.com': false,
    'aim.com': false,
    'airforce.net': false,
    'airforceemail.com': false,
    'airmail.net': false,
    'airpost.net': false,
    'ajacied.com': false,
    'ak47.hu': false,
    'aknet.kg': false,
    'albawaba.com': false,
    'alex4all.com': false,
    'alexandria.cc': false,
    'algeria.com': false,
    'alhilal.net': false,
    'alibaba.com': false,
    'alice.it': false,
    'aliceadsl.fr': false,
    'alive.cz': false,
    'aliyun.com': false,
    'allmail.net': false,
    'alloymail.com': false,
    'allracing.com': false,
    'allsaintsfan.com': false,
    'alltel.net': false,
    'alskens.dk': false,
    'altavista.com': false,
    'altavista.net': false,
    'altavista.se': false,
    'alternativagratis.com': false,
    'alumnidirector.com': false,
    'alvilag.hu': false,
    'amele.com': false,
    'america.hm': false,
    'ameritech.net': false,
    'amnetsal.com': false,
    'amrer.net': false,
    'amuro.net': false,
    'amuromail.com': false,
    'ananzi.co.za': false,
    'ancestry.com': false,
    'andylau.net': false,
    'anfmail.com': false,
    'angelfan.com': false,
    'angelfire.com': false,
    'animal.net': false,
    'animalhouse.com': false,
    'animalwoman.net': false,
    'anjungcafe.com': false,
    'anote.com': false,
    'another.com': false,
    'anotherwin95.com': false,
    'anti-social.com': false,
    'antisocial.com': false,
    'antongijsen.com': false,
    'antwerpen.com': false,
    'anymoment.com': false,
    'anytimenow.com': false,
    'aol.com': false,
    'aol.it': false,
    'apexmail.com': false,
    'apmail.com': false,
    'apollo.lv': false,
    'approvers.net': false,
    'arabia.com': false,
    'arabtop.net': false,
    'arcademaster.com': false,
    'archaeologist.com': false,
    'arcor.de': false,
    'arcotronics.bg': false,
    'argentina.com': false,
    'aristotle.org': false,
    'army.net': false,
    'arnet.com.ar': false,
    'artlover.com': false,
    'artlover.com.au': false,
    'as-if.com': false,
    'asean-mail.com': false,
    'asheville.com': false,
    'asia-links.com': false,
    'asia.com': false,
    'asiafind.com': false,
    'asianavenue.com': false,
    'asiancityweb.com': false,
    'asiansonly.net': false,
    'asianwired.net': false,
    'asiapoint.net': false,
    'assala.com': false,
    'assamesemail.com': false,
    'astroboymail.com': false,
    'astrolover.com': false,
    'astrosfan.com': false,
    'astrosfan.net': false,
    'asurfer.com': false,
    'athenachu.net': false,
    'atina.cl': false,
    'atl.lv': false,
    'atlanticbb.net': false,
    'atlaswebmail.com': false,
    'atlink.com': false,
    'ato.check.com': false,
    'atozasia.com': false,
    'att.net': false,
    'attbi.com': false,
    'attglobal.net': false,
    'attymail.com': false,
    'au.ru': false,
    'ausi.com': false,
    'austin.rr.com': false,
    'australia.edu': false,
    'australiamail.com': false,
    'austrosearch.net': false,
    'autoescuelanerja.com': false,
    'automotiveauthority.com': false,
    'avh.hu': false,
    'awsom.net': false,
    'axoskate.com': false,
    'ayna.com': false,
    'azimiweb.com': false,
    'bachelorboy.com': false,
    'bachelorgal.com': false,
    'backpackers.com': false,
    'backstreet-boys.com': false,
    'backstreetboysclub.com': false,
    'bagherpour.com': false,
    'bangkok.com': false,
    'bangkok2000.com': false,
    'bannertown.net': false,
    'baptistmail.com': false,
    'baptized.com': false,
    'barcelona.com': false,
    'baseballmail.com': false,
    'basketballmail.com': false,
    'batuta.net': false,
    'baudoinconsulting.com': false,
    'bboy.zzn.com': false,
    'bcpl.net': false,
    'bcvibes.com': false,
    'beeebank.com': false,
    'beenhad.com': false,
    'beep.ru': false,
    'beer.com': false,
    'beethoven.com': false,
    'belice.com': false,
    'belizehome.com': false,
    'bellatlantic.net': false,
    'bellnet.ca': false,
    'bellsouth.net': false,
    'berkscounty.com': false,
    'berlin.com': false,
    'berlin.de': false,
    'berlinexpo.de': false,
    'bestmail.us': false,
    'bestweb.net': false,
    'bettergolf.net': false,
    'bev.net': false,
    'bharatmail.com': false,
    'bigassweb.com': false,
    'bigblue.net.au': false,
    'bigboab.com': false,
    'bigfoot.com': false,
    'bigfoot.de': false,
    'bigger.com': false,
    'bigmailbox.com': false,
    'bigpond.com': false,
    'bigpond.com.au': false,
    'bigpond.net.au': false,
    'bigramp.com': false,
    'bikemechanics.com': false,
    'bikeracer.com': false,
    'bikeracers.net': false,
    'bikerider.com': false,
    'billsfan.com': false,
    'billsfan.net': false,
    'bimamail.com': false,
    'bimla.net': false,
    'birdowner.net': false,
    'bisons.com': false,
    'bitmail.com': false,
    'bitpage.net': false,
    'bizhosting.com': false,
    'bla-bla.com': false,
    'blackburnmail.com': false,
    'blackplanet.com': false,
    'blacksburg.net': false,
    'blazemail.com': false,
    'blazenet.net': false,
    'bluehyppo.com': false,
    'bluemail.ch': false,
    'bluemail.dk': false,
    'bluesfan.com': false,
    'bluewin.ch': false,
    'blueyonder.co.uk': false,
    'blushmail.com': false,
    'bmlsports.net': false,
    'boardermail.com': false,
    'boatracers.com': false,
    'bol.com.br': false,
    'bolando.com': false,
    'bollywoodz.com': false,
    'bolt.com': false,
    'boltonfans.com': false,
    'bombdiggity.com': false,
    'bonbon.net': false,
    'boom.com': false,
    'bootmail.com': false,
    'bornnaked.com': false,
    'bossofthemoss.com': false,
    'bostonoffice.com': false,
    'bounce.net': false,
    'box.az': false,
    'boxbg.com': false,
    'boxemail.com': false,
    'boxfrog.com': false,
    'boyzoneclub.com': false,
    'bradfordfans.com': false,
    'brasilia.net': false,
    'brazilmail.com.br': false,
    'breathe.com': false,
    'bresnan.net': false,
    'brfree.com.br': false,
    'bright.net': false,
    'britneyclub.com': false,
    'brittonsign.com': false,
    'broadcast.net': false,
    'bt.com': false,
    'btinternet.com': false,
    'btopenworld.co.uk': false,
    'buffymail.com': false,
    'bullsfan.com': false,
    'bullsgame.com': false,
    'bumerang.ro': false,
    'bunko.com': false,
    'buryfans.com': false,
    'business-man.com': false,
    'businessman.net': false,
    'businessweekmail.com': false,
    'busta-rhymes.com': false,
    'busymail.com': false,
    'buyersusa.com': false,
    'bvimailbox.com': false,
    'byteme.com': false,
    'c2i.net': false,
    'c3.hu': false,
    'c4.com': false,
    'cabacabana.com': false,
    'cableone.net': false,
    'caere.it': false,
    'cairomail.com': false,
    'cais.net': false,
    'callnetuk.com': false,
    'callsign.net': false,
    'caltanet.it': false,
    'camidge.com': false,
    'canada-11.com': false,
    'canada.com': false,
    'canadianmail.com': false,
    'canoemail.com': false,
    'canwetalk.com': false,
    'capu.net': false,
    'caramail.com': false,
    'care2.com': false,
    'careerbuildermail.com': false,
    'carioca.net': false,
    'cartestraina.ro': false,
    'casablancaresort.com': false,
    'casino.com': false,
    'catcha.com': false,
    'catholic.org': false,
    'catlover.com': false,
    'catsrule.garfield.com': false,
    'ccnmail.com': false,
    'cd2.com': false,
    'celineclub.com': false,
    'celtic.com': false,
    'centoper.it': false,
    'centralpets.com': false,
    'centrum.cz': false,
    'centrum.sk': false,
    'centurytel.net': false,
    'cfl.rr.com': false,
    'cgac.es': false,
    'chaiyomail.com': false,
    'chance2mail.com': false,
    'chandrasekar.net': false,
    'charm.net': false,
    'charmedmail.com': false,
    'charter.net': false,
    'chat.ru': false,
    'chattown.com': false,
    'chauhanweb.com': false,
    'check.com': false,
    'check1check.com': false,
    'cheerful.com': false,
    'chek.com': false,
    'chello.nl': false,
    'chemist.com': false,
    'chequemail.com': false,
    'cheyenneweb.com': false,
    'chez.com': false,
    'chickmail.com': false,
    'china.net.vg': false,
    'chinalook.com': false,
    'chirk.com': false,
    'chocaholic.com.au': false,
    'christianmail.net': false,
    'churchusa.com': false,
    'cia-agent.com': false,
    'cia.hu': false,
    'ciaoweb.it': false,
    'cicciociccio.com': false,
    'cincinow.net': false,
    'citeweb.net': false,
    'citlink.net': false,
    'city-of-bath.org': false,
    'city-of-birmingham.com': false,
    'city-of-brighton.org': false,
    'city-of-cambridge.com': false,
    'city-of-coventry.com': false,
    'city-of-edinburgh.com': false,
    'city-of-lichfield.com': false,
    'city-of-lincoln.com': false,
    'city-of-liverpool.com': false,
    'city-of-manchester.com': false,
    'city-of-nottingham.com': false,
    'city-of-oxford.com': false,
    'city-of-swansea.com': false,
    'city-of-westminster.com': false,
    'city-of-westminster.net': false,
    'city-of-york.net': false,
    'city2city.com': false,
    'cityofcardiff.net': false,
    'cityoflondon.org': false,
    'claramail.com': false,
    'classicalfan.com': false,
    'classicmail.co.za': false,
    'clerk.com': false,
    'cliffhanger.com': false,
    'close2you.net': false,
    'club-internet.fr': false,
    'club4x4.net': false,
    'clubalfa.com': false,
    'clubbers.net': false,
    'clubducati.com': false,
    'clubhonda.net': false,
    'clubvdo.net': false,
    'cluemail.com': false,
    'cmpmail.com': false,
    'cnnsimail.com': false,
    'codec.ro': false,
    'coder.hu': false,
    'coid.biz': false,
    'coldmail.com': false,
    'collectiblesuperstore.com': false,
    'collegebeat.com': false,
    'collegeclub.com': false,
    'collegemail.com': false,
    'colleges.com': false,
    'columbus.rr.com': false,
    'columbusrr.com': false,
    'columnist.com': false,
    'comcast.net': false,
    'comic.com': false,
    'communityconnect.com': false,
    'comprendemail.com': false,
    'compuserve.com': false,
    'computer-freak.com': false,
    'computermail.net': false,
    'concentric.net': false,
    'conexcol.com': false,
    'conk.com': false,
    'connect4free.net': false,
    'connectbox.com': false,
    'conok.com': false,
    'consultant.com': false,
    'cookiemonster.com': false,
    'cool.br': false,
    'coolgoose.ca': false,
    'coolgoose.com': false,
    'coolkiwi.com': false,
    'coollist.com': false,
    'coolmail.com': false,
    'coolmail.net': false,
    'coolsend.com': false,
    'cooooool.com': false,
    'cooperation.net': false,
    'cooperationtogo.net': false,
    'copacabana.com': false,
    'cornells.com': false,
    'cornerpub.com': false,
    'corporatedirtbag.com': false,
    'correo.terra.com.gt': false,
    'cortinet.com': false,
    'cotas.net': false,
    'counsellor.com': false,
    'countrylover.com': false,
    'covad.net': false,
    'cox.net': false,
    'coxinet.net': false,
    'coxmail.com': false,
    'cpaonline.net': false,
    'cracker.hu': false,
    'crazedanddazed.com': false,
    'crazysexycool.com': false,
    'cristianemail.com': false,
    'critterpost.com': false,
    'croeso.com': false,
    'crosshairs.com': false,
    'crosslink.net': false,
    'crosswinds.net': false,
    'crwmail.com': false,
    'cry4helponline.com': false,
    'cs.com': false,
    'csi.com': false,
    'csinibaba.hu': false,
    'cuemail.com': false,
    'curio-city.com': false,
    'cute-girl.com': false,
    'cuteandcuddly.com': false,
    'cutey.com': false,
    'cww.de': false,
    'cyber-africa.net': false,
    'cyber4all.com': false,
    'cyberbabies.com': false,
    'cybercafemaui.com': false,
    'cyberdude.com': false,
    'cyberforeplay.net': false,
    'cybergal.com': false,
    'cybergrrl.com': false,
    'cyberinbox.com': false,
    'cyberleports.com': false,
    'cybermail.net': false,
    'cybernet.it': false,
    'cyberspace-asia.com': false,
    'cybertrains.org': false,
    'cyclefanz.com': false,
    'cynetcity.com': false,
    'dabsol.net': false,
    'dadacasa.com': false,
    'daha.com': false,
    'dailypioneer.com': false,
    'dallas.theboys.com': false,
    'dangerous-minds.com': false,
    'dansegulvet.com': false,
    'data54.com': false,
    'daum.net': false,
    'davegracey.com': false,
    'dawnsonmail.com': false,
    'dawsonmail.com': false,
    'dazedandconfused.com': false,
    'dbzmail.com': false,
    'dca.net': false,
    'dcemail.com': false,
    'deadlymob.org': false,
    'deal-maker.com': false,
    'dearriba.com': false,
    'death-star.com': false,
    'dejanews.com': false,
    'deliveryman.com': false,
    'deltanet.com': false,
    'deneg.net': false,
    'depechemode.com': false,
    'deseretmail.com': false,
    'desertmail.com': false,
    'desilota.com': false,
    'deskmail.com': false,
    'deskpilot.com': false,
    'destin.com': false,
    'detik.com': false,
    'deutschland-net.com': false,
    'devotedcouples.com': false,
    'dfwatson.com': false,
    'di-ve.com': false,
    'digibel.be': false,
    'diplomats.com': false,
    'direcway.com': false,
    'dirtracer.com': false,
    'discofan.com': false,
    'discovery.com': false,
    'discoverymail.com': false,
    'disinfo.net': false,
    'dmailman.com': false,
    'dmv.com': false,
    'dnsmadeeasy.com': false,
    'doctor.com': false,
    'dog.com': false,
    'doglover.com': false,
    'dogmail.co.uk': false,
    'dogsnob.net': false,
    'doityourself.com': false,
    'doneasy.com': false,
    'donjuan.com': false,
    'dontgotmail.com': false,
    'dontmesswithtexas.com': false,
    'doramail.com': false,
    'dostmail.com': false,
    'dotcom.fr': false,
    'dott.it': false,
    'dplanet.ch': false,
    'dr.com': false,
    'dragoncon.net': false,
    'dragracer.com': false,
    'dropzone.com': false,
    'drotposta.hu': false,
    'dslextreme.com': false,
    'dubaimail.com': false,
    'dublin.com': false,
    'dublin.ie': false,
    'dunlopdriver.com': false,
    'dunloprider.com': false,
    'duno.com': false,
    'dwp.net': false,
    'dygo.com': false,
    'dynamitemail.com': false,
    'e-apollo.lv': false,
    'e-mail.dk': false,
    'e-mail.ru': false,
    'e-mailanywhere.com': false,
    'e-mails.ru': false,
    'e-tapaal.com': false,
    'earthalliance.com': false,
    'earthcam.net': false,
    'earthdome.com': false,
    'earthling.net': false,
    'earthlink.net': false,
    'earthonline.net': false,
    'eastcoast.co.za': false,
    'eastmail.com': false,
    'easy.to': false,
    'easypost.com': false,
    'eatmydirt.com': false,
    'ecardmail.com': false,
    'ecbsolutions.net': false,
    'echina.com': false,
    'ecompare.com': false,
    'edge.net': false,
    'edmail.com': false,
    'ednatx.com': false,
    'edtnmail.com': false,
    'educacao.te.pt': false,
    'educastmail.com': false,
    'ehmail.com': false,
    'eircom.net': false,
    'elsitio.com': false,
    'elvis.com': false,
    'email-london.co.uk': false,
    'email.com': false,
    'email.cz': false,
    'email.ee': false,
    'email.it': false,
    'email.nu': false,
    'email.ro': false,
    'email.ru': false,
    'email.si': false,
    'email.women.com': false,
    'email2me.net': false,
    'emailacc.com': false,
    'emailaccount.com': false,
    'emailchoice.com': false,
    'emailcorner.net': false,
    'emailem.com': false,
    'emailengine.net': false,
    'emailforyou.net': false,
    'emailgroups.net': false,
    'emailpinoy.com': false,
    'emailplanet.com': false,
    'emails.ru': false,
    'emailuser.net': false,
    'emailx.net': false,
    'ematic.com': false,
    'embarqmail.com': false,
    'emumail.com': false,
    'end-war.com': false,
    'enel.net': false,
    'engineer.com': false,
    'england.com': false,
    'england.edu': false,
    'enter.net': false,
    'epatra.com': false,
    'epix.net': false,
    'epost.de': false,
    'eposta.hu': false,
    'eqqu.com': false,
    'eramail.co.za': false,
    'eresmas.com': false,
    'eriga.lv': false,
    'erols.com': false,
    'estranet.it': false,
    'ethos.st': false,
    'etoast.com': false,
    'etrademail.com': false,
    'eudoramail.com': false,
    'europe.com': false,
    'euroseek.com': false,
    'every1.net': false,
    'everyday.com.kh': false,
    'everyone.net': false,
    'examnotes.net': false,
    'excite.co.jp': false,
    'excite.com': false,
    'excite.it': false,
    'execs.com': false,
    'expressasia.com': false,
    'extenda.net': false,
    'extended.com': false,
    'eyou.com': false,
    'ezcybersearch.com': false,
    'ezmail.egine.com': false,
    'ezmail.ru': false,
    'ezrs.com': false,
    'ezy.net': false,
    'f1fans.net': false,
    'facebook.com': false,
    'fan.com': false,
    'fan.theboys.com': false,
    'fansonlymail.com': false,
    'fantasticmail.com': false,
    'farang.net': false,
    'faroweb.com': false,
    'fastem.com': false,
    'fastemail.us': false,
    'fastemailer.com': false,
    'fastermail.com': false,
    'fastimap.com': false,
    'fastmail.fm': false,
    'fastmailbox.net': false,
    'fastmessaging.com': false,
    'fatcock.net': false,
    'fathersrightsne.org': false,
    'fbi-agent.com': false,
    'fbi.hu': false,
    'fcc.net': false,
    'federalcontractors.com': false,
    'felicity.com': false,
    'felicitymail.com': false,
    'femenino.com': false,
    'fetchmail.co.uk': false,
    'fetchmail.com': false,
    'feyenoorder.com': false,
    'ffanet.com': false,
    'fiberia.com': false,
    'fibertel.com.ar': false,
    'filipinolinks.com': false,
    'financemail.net': false,
    'financier.com': false,
    'findmail.com': false,
    'finebody.com': false,
    'finfin.com': false,
    'fire-brigade.com': false,
    'fishburne.org': false,
    'flashcom.net': false,
    'flashemail.com': false,
    'flashmail.com': false,
    'flashmail.net': false,
    'flipcode.com': false,
    'fmail.co.uk': false,
    'fmailbox.com': false,
    'fmgirl.com': false,
    'fmguy.com': false,
    'fnbmail.co.za': false,
    'fnmail.com': false,
    'folkfan.com': false,
    'foodmail.com': false,
    'football.theboys.com': false,
    'footballmail.com': false,
    'for-president.com': false,
    'forfree.at': false,
    'forpresident.com': false,
    'forthnet.gr': false,
    'fortuncity.com': false,
    'fortunecity.com': false,
    'forum.dk': false,
    'foxmail.com': false,
    'free-org.com': false,
    'free.com.pe': false,
    'free.fr': false,
    'freeaccess.nl': false,
    'freeaccount.com': false,
    'freeandsingle.com': false,
    'freedom.usa.com': false,
    'freedomlover.com': false,
    'freegates.be': false,
    'freeghana.com': false,
    'freeler.nl': false,
    'freemail.c3.hu': false,
    'freemail.com.au': false,
    'freemail.com.pk': false,
    'freemail.de': false,
    'freemail.et': false,
    'freemail.gr': false,
    'freemail.hu': false,
    'freemail.it': false,
    'freemail.lt': false,
    'freemail.nl': false,
    'freemail.org.mk': false,
    'freenet.de': false,
    'freenet.kg': false,
    'freeola.com': false,
    'freeola.net': false,
    'freeserve.co.uk': false,
    'freestamp.com': false,
    'freestart.hu': false,
    'freesurf.fr': false,
    'freesurf.nl': false,
    'freeuk.com': false,
    'freeuk.net': false,
    'freeukisp.co.uk': false,
    'freeweb.org': false,
    'freewebemail.com': false,
    'freeyellow.com': false,
    'freezone.co.uk': false,
    'fresnomail.com': false,
    'friends-cafe.com': false,
    'friendsfan.com': false,
    'from-africa.com': false,
    'from-america.com': false,
    'from-argentina.com': false,
    'from-asia.com': false,
    'from-australia.com': false,
    'from-belgium.com': false,
    'from-brazil.com': false,
    'from-canada.com': false,
    'from-china.net': false,
    'from-england.com': false,
    'from-europe.com': false,
    'from-france.net': false,
    'from-germany.net': false,
    'from-holland.com': false,
    'from-israel.com': false,
    'from-italy.net': false,
    'from-japan.net': false,
    'from-korea.com': false,
    'from-mexico.com': false,
    'from-outerspace.com': false,
    'from-russia.com': false,
    'from-spain.net': false,
    'fromalabama.com': false,
    'fromalaska.com': false,
    'fromarizona.com': false,
    'fromarkansas.com': false,
    'fromcalifornia.com': false,
    'fromcolorado.com': false,
    'fromconnecticut.com': false,
    'fromdelaware.com': false,
    'fromflorida.net': false,
    'fromgeorgia.com': false,
    'fromhawaii.net': false,
    'fromidaho.com': false,
    'fromillinois.com': false,
    'fromindiana.com': false,
    'fromiowa.com': false,
    'fromjupiter.com': false,
    'fromkansas.com': false,
    'fromkentucky.com': false,
    'fromlouisiana.com': false,
    'frommaine.net': false,
    'frommaryland.com': false,
    'frommassachusetts.com': false,
    'frommiami.com': false,
    'frommichigan.com': false,
    'fromminnesota.com': false,
    'frommississippi.com': false,
    'frommissouri.com': false,
    'frommontana.com': false,
    'fromnebraska.com': false,
    'fromnevada.com': false,
    'fromnewhampshire.com': false,
    'fromnewjersey.com': false,
    'fromnewmexico.com': false,
    'fromnewyork.net': false,
    'fromnorthcarolina.com': false,
    'fromnorthdakota.com': false,
    'fromohio.com': false,
    'fromoklahoma.com': false,
    'fromoregon.net': false,
    'frompennsylvania.com': false,
    'fromrhodeisland.com': false,
    'fromru.com': false,
    'fromsouthcarolina.com': false,
    'fromsouthdakota.com': false,
    'fromtennessee.com': false,
    'fromtexas.com': false,
    'fromthestates.com': false,
    'fromutah.com': false,
    'fromvermont.com': false,
    'fromvirginia.com': false,
    'fromwashington.com': false,
    'fromwashingtondc.com': false,
    'fromwestvirginia.com': false,
    'fromwisconsin.com': false,
    'fromwyoming.com': false,
    'front.ru': false,
    'frontier.com': false,
    'frontiernet.net': false,
    'frostbyte.uk.net': false,
    'fsmail.net': false,
    'ftml.net': false,
    'fullchannel.net': false,
    'fullmail.com': false,
    'funkfan.com': false,
    'fuorissimo.com': false,
    'furnitureprovider.com': false,
    'fuse.net': false,
    'fut.es': false,
    'fwnb.com': false,
    'fxsmails.com': false,
    'galamb.net': false,
    'galaxy5.com': false,
    'gamebox.net': false,
    'gamegeek.com': false,
    'games.com': false,
    'gamespotmail.com': false,
    'garbage.com': false,
    'gardener.com': false,
    'gateway.net': false,
    'gawab.com': false,
    'gaybrighton.co.uk': false,
    'gaza.net': false,
    'gazeta.pl': false,
    'gazibooks.com': false,
    'gci.net': false,
    'gee-wiz.com': false,
    'geecities.com': false,
    'geek.com': false,
    'geek.hu': false,
    'geeklife.com': false,
    'general-hospital.com': false,
    'geocities.com': false,
    'geologist.com': false,
    'geopia.com': false,
    'gh2000.com': false,
    'ghanamail.com': false,
    'ghostmail.com': false,
    'giantsfan.com': false,
    'giga4u.de': false,
    'gigileung.org': false,
    'givepeaceachance.com': false,
    'glay.org': false,
    'glendale.net': false,
    'globalfree.it': false,
    'globalpagan.com': false,
    'globalsite.com.br': false,
    'globo.com': false,
    'globomail.com': false,
    'gmail.com': false,
    'gmail.co': false,
    'gmx.at': false,
    'gmx.com': false,
    'gmx.de': false,
    'gmx.fr': false,
    'gmx.li': false,
    'gmx.net': false,
    'gnwmail.com': false,
    'go.com': false,
    'go.ro': false,
    'go.ru': false,
    'go2.com.py': false,
    'go2net.com': false,
    'gocollege.com': false,
    'gocubs.com': false,
    'gofree.co.uk': false,
    'goldenmail.ru': false,
    'goldmail.ru': false,
    'golfemail.com': false,
    'golfmail.be': false,
    'gonavy.net': false,
    'goodstick.com': false,
    'google.com': false,
    'googlemail.com': false,
    'goplay.com': false,
    'gorontalo.net': false,
    'gospelfan.com': false,
    'gothere.uk.com': false,
    'gotmail.com': false,
    'gotomy.com': false,
    'govolsfan.com': false,
    'gportal.hu': false,
    'grabmail.com': false,
    'graffiti.net': false,
    'gramszu.net': false,
    'grapplers.com': false,
    'gratisweb.com': false,
    'grungecafe.com': false,
    'gtemail.net': false,
    'gti.net': false,
    'gtmc.net': false,
    'gua.net': false,
    'guessmail.com': false,
    'guju.net': false,
    'gurlmail.com': false,
    'guy.com': false,
    'guy2.com': false,
    'guyanafriends.com': false,
    'gyorsposta.com': false,
    'gyorsposta.hu': false,
    'hackermail.net': false,
    'hailmail.net': false,
    'hairdresser.net': false,
    'hamptonroads.com': false,
    'handbag.com': false,
    'handleit.com': false,
    'hang-ten.com': false,
    'hanmail.net': false,
    'happemail.com': false,
    'happycounsel.com': false,
    'happypuppy.com': false,
    'hardcorefreak.com': false,
    'hawaii.rr.com': false,
    'hawaiiantel.net': false,
    'headbone.com': false,
    'heartthrob.com': false,
    'heerschap.com': false,
    'heesun.net': false,
    'hehe.com': false,
    'hello.hu': false,
    'hello.net.au': false,
    'hello.to': false,
    'helter-skelter.com': false,
    'hempseed.com': false,
    'herediano.com': false,
    'heremail.com': false,
    'herono1.com': false,
    'hetnet.nl': false,
    'hey.to': false,
    'hhdevel.com': false,
    'highmilton.com': false,
    'highquality.com': false,
    'highveldmail.co.za': false,
    'hiphopfan.com': false,
    'hispavista.com': false,
    'hitthe.net': false,
    'hkg.net': false,
    'hkstarphoto.com': false,
    'hockeymail.com': false,
    'hollywoodkids.com': false,
    'home-email.com': false,
    'home.nl': false,
    'home.no.net': false,
    'home.ro': false,
    'home.se': false,
    'homeart.com': false,
    'homelocator.com': false,
    'homemail.com': false,
    'homestead.com': false,
    'homeworkcentral.com': false,
    'hongkong.com': false,
    'hookup.net': false,
    'hoopsmail.com': false,
    'horrormail.com': false,
    'host-it.com.sg': false,
    'hot-shot.com': false,
    'hot.ee': false,
    'hotbot.com': false,
    'hotbrev.com': false,
    'hotepmail.com': false,
    'hotfire.net': false,
    'hotletter.com': false,
    'hotmail.be': false,
    'hotmail.co.il': false,
    'hotmail.co.uk': false,
    'hotmail.com': false,
    'hotmail.com.ar': false,
    'hotmail.com.br': false,
    'hotmail.com.mx': false,
    'hotmail.de': false,
    'hotmail.es': false,
    'hotmail.fr': false,
    'hotmail.it': false,
    'hotmail.kg': false,
    'hotmail.kz': false,
    'hotmail.ru': false,
    'hotpop.com': false,
    'hotpop3.com': false,
    'hotvoice.com': false,
    'housefan.com': false,
    'housemail.com': false,
    'hsuchi.net': false,
    'html.tou.com': false,
    'hughes.net': false,
    'hunsa.com': false,
    'hush.com': false,
    'hushmail.com': false,
    'hypernautica.com': false,
    'i-connect.com': false,
    'i-france.com': false,
    'i-mail.com.au': false,
    'i-p.com': false,
    'i-plus.net': false,
    'i.am': false,
    'i12.com': false,
    'iamawoman.com': false,
    'iamwaiting.com': false,
    'iamwasted.com': false,
    'iamyours.com': false,
    'ibm.net': false,
    'icestorm.com': false,
    'icloud.com': false,
    'icmsconsultants.com': false,
    'icq.com': false,
    'icqmail.com': false,
    'icrazy.com': false,
    'id-base.com': false,
    'ididitmyway.com': false,
    'idirect.com': false,
    'iespana.es': false,
    'ifoward.com': false,
    'ig.com.br': false,
    'ignazio.it': false,
    'ignmail.com': false,
    'ihateclowns.com': false,
    'ihwy.com': false,
    'iinet.net.au': false,
    'ijustdontcare.com': false,
    'ilovechocolate.com': false,
    'ilovejesus.com': false,
    'ilovethemovies.com': false,
    'ilovetocollect.net': false,
    'ilse.nl': false,
    'imaginemail.com': false,
    'imail.org': false,
    'imail.ru': false,
    'imailbox.com': false,
    'imel.org': false,
    'imneverwrong.com': false,
    'imposter.co.uk': false,
    'imstressed.com': false,
    'imtoosexy.com': false,
    'in-box.net': false,
    'iname.com': false,
    'inbox.com': false,
    'inbox.net': false,
    'inbox.ru': false,
    'incamail.com': false,
    'incredimail.com': false,
    'indexa.fr': false,
    'india.com': false,
    'indiatimes.com': false,
    'indo-mail.com': false,
    'indocities.com': false,
    'indomail.com': false,
    'indyracers.com': false,
    'info-media.de': false,
    'info66.com': false,
    'infohq.com': false,
    'infomail.es': false,
    'infomart.or.jp': false,
    'infospacemail.com': false,
    'infovia.com.ar': false,
    'inicia.es': false,
    'inmail.sk': false,
    'innocent.com': false,
    'inorbit.com': false,
    'insidebaltimore.net': false,
    'insight.rr.com': false,
    'insurer.com': false,
    'integra.net': false,
    'interaccess.com': false,
    'interburp.com': false,
    'interfree.it': false,
    'interia.pl': false,
    'interlap.com.ar': false,
    'intermail.co.il': false,
    'internet-club.com': false,
    'internet-police.com': false,
    'internetbiz.com': false,
    'internetdrive.com': false,
    'internetegypt.com': false,
    'internetemails.net': false,
    'internetmailing.net': false,
    'internetmci.com': false,
    'investormail.com': false,
    'inwind.it': false,
    'iobox.com': false,
    'iobox.fi': false,
    'iol.it': false,
    'ionet.net': false,
    'iowaemail.com': false,
    'ip3.com': false,
    'iprimus.com.au': false,
    'iqemail.com': false,
    'irangate.net': false,
    'iraqmail.com': false,
    'ireland.com': false,
    'irj.hu': false,
    'isellcars.com': false,
    'islamonline.net': false,
    'isleuthmail.com': false,
    'ismart.net': false,
    'isonfire.com': false,
    'isp9.net': false,
    'itelefonica.com.br': false,
    'itloox.com': false,
    'itmom.com': false,
    'itol.com': false,
    'ivebeenframed.com': false,
    'ivillage.com': false,
    'iwan-fals.com': false,
    'iwmail.com': false,
    'iwon.com': false,
    'izadpanah.com': false,
    'jahoopa.com': false,
    'jakuza.hu': false,
    'japan.com': false,
    'jaydemail.com': false,
    'jazzandjava.com': false,
    'jazzfan.com': false,
    'jazzgame.com': false,
    'jerusalemmail.com': false,
    'jetemail.net': false,
    'jewishmail.com': false,
    'jippii.fi': false,
    'jmail.co.za': false,
    'joinme.com': false,
    'jokes.com': false,
    'jordanmail.com': false,
    'journalist.com': false,
    'jovem.te.pt': false,
    'joymail.com': false,
    'jpopmail.com': false,
    'jubiimail.dk': false,
    'jump.com': false,
    'jumpy.it': false,
    'juniormail.com': false,
    'juno.com': false,
    'justemail.net': false,
    'justicemail.com': false,
    'kaazoo.com': false,
    'kaixo.com': false,
    'kalpoint.com': false,
    'kansascity.com': false,
    'kapoorweb.com': false,
    'karachian.com': false,
    'karachioye.com': false,
    'karbasi.com': false,
    'katamail.com': false,
    'kayafmmail.co.za': false,
    'kbjrmail.com': false,
    'kcks.com': false,
    'keg-party.com': false,
    'keko.com.ar': false,
    'kellychen.com': false,
    'keromail.com': false,
    'keyemail.com': false,
    'kgb.hu': false,
    'khosropour.com': false,
    'kickassmail.com': false,
    'killermail.com': false,
    'kimo.com': false,
    'kinki-kids.com': false,
    'kittymail.com': false,
    'kitznet.at': false,
    'kiwibox.com': false,
    'kiwitown.com': false,
    'kmail.com.au': false,
    'konx.com': false,
    'korea.com': false,
    'kozmail.com': false,
    'krongthip.com': false,
    'krunis.com': false,
    'ksanmail.com': false,
    'ksee24mail.com': false,
    'kube93mail.com': false,
    'kukamail.com': false,
    'kumarweb.com': false,
    'kuwait-mail.com': false,
    'la.com': false,
    'ladymail.cz': false,
    'lagerlouts.com': false,
    'lahoreoye.com': false,
    'lakmail.com': false,
    'lamer.hu': false,
    'land.ru': false,
    'lankamail.com': false,
    'laposte.net': false,
    'latemodels.com': false,
    'latinmail.com': false,
    'latino.com': false,
    'lavabit.com': false,
    'law.com': false,
    'lawyer.com': false,
    'leehom.net': false,
    'legalactions.com': false,
    'legislator.com': false,
    'leonlai.net': false,
    'letsgomets.net': false,
    'letterbox.com': false,
    'levele.com': false,
    'levele.hu': false,
    'lex.bg': false,
    'lexis-nexis-mail.com': false,
    'libero.it': false,
    'liberomail.com': false,
    'lick101.com': false,
    'linkmaster.com': false,
    'linktrader.com': false,
    'linuxfreemail.com': false,
    'linuxmail.org': false,
    'lionsfan.com.au': false,
    'liontrucks.com': false,
    'liquidinformation.net': false,
    'list.ru': false,
    'littleblueroom.com': false,
    'live.be': false,
    'live.ca': false,
    'live.co.uk': false,
    'live.com': false,
    'live.com.ar': false,
    'live.com.au': false,
    'live.com.mx': false,
    'live.de': false,
    'live.fr': false,
    'live.it': false,
    'live.nl': false,
    'liverpoolfans.com': false,
    'llandudno.com': false,
    'llangollen.com': false,
    'lmxmail.sk': false,
    'lobbyist.com': false,
    'localbar.com': false,
    'london.com': false,
    'loobie.com': false,
    'looksmart.co.uk': false,
    'looksmart.com': false,
    'looksmart.com.au': false,
    'lopezclub.com': false,
    'louiskoo.com': false,
    'love.com': false,
    'love.cz': false,
    'loveable.com': false,
    'lovelygirl.net': false,
    'lovemail.com': false,
    'lover-boy.com': false,
    'lovergirl.com': false,
    'lovingjesus.com': false,
    'lowandslow.com': false,
    'luso.pt': false,
    'luukku.com': false,
    'lycos.co.uk': false,
    'lycos.com': false,
    'lycos.es': false,
    'lycos.it': false,
    'lycos.ne.jp': false,
    'lycosemail.com': false,
    'lycosmail.com': false,
    'm-a-i-l.com': false,
    'm-hmail.com': false,
    'm4.org': false,
    'mac.com': false,
    'macbox.com': false,
    'macfreak.com': false,
    'machinecandy.com': false,
    'macmail.com': false,
    'madcreations.com': false,
    'madrid.com': false,
    'maffia.hu': false,
    'magicmail.co.za': false,
    'mahmoodweb.com': false,
    'mail-awu.de': false,
    'mail-box.cz': false,
    'mail-center.com': false,
    'mail-central.com': false,
    'mail-page.com': false,
    'mail.austria.com': false,
    'mail.az': false,
    'mail.be': false,
    'mail.bulgaria.com': false,
    'mail.byte.it': false,
    'mail.co.za': false,
    'mail.com': false,
    'mail.ee': false,
    'mail.entrepeneurmag.com': false,
    'mail.freetown.com': false,
    'mail.gr': false,
    'mail.hitthebeach.com': false,
    'mail.kmsp.com': false,
    'mail.md': false,
    'mail.nu': false,
    'mail.org.uk': false,
    'mail.pf': false,
    'mail.pharmacy.com': false,
    'mail.pt': false,
    'mail.r-o-o-t.com': false,
    'mail.ru': false,
    'mail.salu.net': false,
    'mail.sisna.com': false,
    'mail.spaceports.com': false,
    'mail.theboys.com': false,
    'mail.usa.com': false,
    'mail.vasarhely.hu': false,
    'mail15.com': false,
    'mail1st.com': false,
    'mail2007.com': false,
    'mail2aaron.com': false,
    'mail2abby.com': false,
    'mail2abc.com': false,
    'mail2actor.com': false,
    'mail2admiral.com': false,
    'mail2adorable.com': false,
    'mail2adoration.com': false,
    'mail2adore.com': false,
    'mail2adventure.com': false,
    'mail2aeolus.com': false,
    'mail2aether.com': false,
    'mail2affection.com': false,
    'mail2afghanistan.com': false,
    'mail2africa.com': false,
    'mail2agent.com': false,
    'mail2aha.com': false,
    'mail2ahoy.com': false,
    'mail2aim.com': false,
    'mail2air.com': false,
    'mail2airbag.com': false,
    'mail2airforce.com': false,
    'mail2airport.com': false,
    'mail2alabama.com': false,
    'mail2alan.com': false,
    'mail2alaska.com': false,
    'mail2albania.com': false,
    'mail2alcoholic.com': false,
    'mail2alec.com': false,
    'mail2alexa.com': false,
    'mail2algeria.com': false,
    'mail2alicia.com': false,
    'mail2alien.com': false,
    'mail2allan.com': false,
    'mail2allen.com': false,
    'mail2allison.com': false,
    'mail2alpha.com': false,
    'mail2alyssa.com': false,
    'mail2amanda.com': false,
    'mail2amazing.com': false,
    'mail2amber.com': false,
    'mail2america.com': false,
    'mail2american.com': false,
    'mail2andorra.com': false,
    'mail2andrea.com': false,
    'mail2andy.com': false,
    'mail2anesthesiologist.com': false,
    'mail2angela.com': false,
    'mail2angola.com': false,
    'mail2ann.com': false,
    'mail2anna.com': false,
    'mail2anne.com': false,
    'mail2anthony.com': false,
    'mail2anything.com': false,
    'mail2aphrodite.com': false,
    'mail2apollo.com': false,
    'mail2april.com': false,
    'mail2aquarius.com': false,
    'mail2arabia.com': false,
    'mail2arabic.com': false,
    'mail2architect.com': false,
    'mail2ares.com': false,
    'mail2argentina.com': false,
    'mail2aries.com': false,
    'mail2arizona.com': false,
    'mail2arkansas.com': false,
    'mail2armenia.com': false,
    'mail2army.com': false,
    'mail2arnold.com': false,
    'mail2art.com': false,
    'mail2artemus.com': false,
    'mail2arthur.com': false,
    'mail2artist.com': false,
    'mail2ashley.com': false,
    'mail2ask.com': false,
    'mail2astronomer.com': false,
    'mail2athena.com': false,
    'mail2athlete.com': false,
    'mail2atlas.com': false,
    'mail2atom.com': false,
    'mail2attitude.com': false,
    'mail2auction.com': false,
    'mail2aunt.com': false,
    'mail2australia.com': false,
    'mail2austria.com': false,
    'mail2azerbaijan.com': false,
    'mail2baby.com': false,
    'mail2bahamas.com': false,
    'mail2bahrain.com': false,
    'mail2ballerina.com': false,
    'mail2ballplayer.com': false,
    'mail2band.com': false,
    'mail2bangladesh.com': false,
    'mail2bank.com': false,
    'mail2banker.com': false,
    'mail2bankrupt.com': false,
    'mail2baptist.com': false,
    'mail2bar.com': false,
    'mail2barbados.com': false,
    'mail2barbara.com': false,
    'mail2barter.com': false,
    'mail2basketball.com': false,
    'mail2batter.com': false,
    'mail2beach.com': false,
    'mail2beast.com': false,
    'mail2beatles.com': false,
    'mail2beauty.com': false,
    'mail2becky.com': false,
    'mail2beijing.com': false,
    'mail2belgium.com': false,
    'mail2belize.com': false,
    'mail2ben.com': false,
    'mail2bernard.com': false,
    'mail2beth.com': false,
    'mail2betty.com': false,
    'mail2beverly.com': false,
    'mail2beyond.com': false,
    'mail2biker.com': false,
    'mail2bill.com': false,
    'mail2billionaire.com': false,
    'mail2billy.com': false,
    'mail2bio.com': false,
    'mail2biologist.com': false,
    'mail2black.com': false,
    'mail2blackbelt.com': false,
    'mail2blake.com': false,
    'mail2blind.com': false,
    'mail2blonde.com': false,
    'mail2blues.com': false,
    'mail2bob.com': false,
    'mail2bobby.com': false,
    'mail2bolivia.com': false,
    'mail2bombay.com': false,
    'mail2bonn.com': false,
    'mail2bookmark.com': false,
    'mail2boreas.com': false,
    'mail2bosnia.com': false,
    'mail2boston.com': false,
    'mail2botswana.com': false,
    'mail2bradley.com': false,
    'mail2brazil.com': false,
    'mail2breakfast.com': false,
    'mail2brian.com': false,
    'mail2bride.com': false,
    'mail2brittany.com': false,
    'mail2broker.com': false,
    'mail2brook.com': false,
    'mail2bruce.com': false,
    'mail2brunei.com': false,
    'mail2brunette.com': false,
    'mail2brussels.com': false,
    'mail2bryan.com': false,
    'mail2bug.com': false,
    'mail2bulgaria.com': false,
    'mail2business.com': false,
    'mail2buy.com': false,
    'mail2ca.com': false,
    'mail2california.com': false,
    'mail2calvin.com': false,
    'mail2cambodia.com': false,
    'mail2cameroon.com': false,
    'mail2canada.com': false,
    'mail2cancer.com': false,
    'mail2capeverde.com': false,
    'mail2capricorn.com': false,
    'mail2cardinal.com': false,
    'mail2cardiologist.com': false,
    'mail2care.com': false,
    'mail2caroline.com': false,
    'mail2carolyn.com': false,
    'mail2casey.com': false,
    'mail2cat.com': false,
    'mail2caterer.com': false,
    'mail2cathy.com': false,
    'mail2catlover.com': false,
    'mail2catwalk.com': false,
    'mail2cell.com': false,
    'mail2chad.com': false,
    'mail2champaign.com': false,
    'mail2charles.com': false,
    'mail2chef.com': false,
    'mail2chemist.com': false,
    'mail2cherry.com': false,
    'mail2chicago.com': false,
    'mail2chile.com': false,
    'mail2china.com': false,
    'mail2chinese.com': false,
    'mail2chocolate.com': false,
    'mail2christian.com': false,
    'mail2christie.com': false,
    'mail2christmas.com': false,
    'mail2christy.com': false,
    'mail2chuck.com': false,
    'mail2cindy.com': false,
    'mail2clark.com': false,
    'mail2classifieds.com': false,
    'mail2claude.com': false,
    'mail2cliff.com': false,
    'mail2clinic.com': false,
    'mail2clint.com': false,
    'mail2close.com': false,
    'mail2club.com': false,
    'mail2coach.com': false,
    'mail2coastguard.com': false,
    'mail2colin.com': false,
    'mail2college.com': false,
    'mail2colombia.com': false,
    'mail2color.com': false,
    'mail2colorado.com': false,
    'mail2columbia.com': false,
    'mail2comedian.com': false,
    'mail2composer.com': false,
    'mail2computer.com': false,
    'mail2computers.com': false,
    'mail2concert.com': false,
    'mail2congo.com': false,
    'mail2connect.com': false,
    'mail2connecticut.com': false,
    'mail2consultant.com': false,
    'mail2convict.com': false,
    'mail2cook.com': false,
    'mail2cool.com': false,
    'mail2cory.com': false,
    'mail2costarica.com': false,
    'mail2country.com': false,
    'mail2courtney.com': false,
    'mail2cowboy.com': false,
    'mail2cowgirl.com': false,
    'mail2craig.com': false,
    'mail2crave.com': false,
    'mail2crazy.com': false,
    'mail2create.com': false,
    'mail2croatia.com': false,
    'mail2cry.com': false,
    'mail2crystal.com': false,
    'mail2cuba.com': false,
    'mail2culture.com': false,
    'mail2curt.com': false,
    'mail2customs.com': false,
    'mail2cute.com': false,
    'mail2cutey.com': false,
    'mail2cynthia.com': false,
    'mail2cyprus.com': false,
    'mail2czechrepublic.com': false,
    'mail2dad.com': false,
    'mail2dale.com': false,
    'mail2dallas.com': false,
    'mail2dan.com': false,
    'mail2dana.com': false,
    'mail2dance.com': false,
    'mail2dancer.com': false,
    'mail2danielle.com': false,
    'mail2danny.com': false,
    'mail2darlene.com': false,
    'mail2darling.com': false,
    'mail2darren.com': false,
    'mail2daughter.com': false,
    'mail2dave.com': false,
    'mail2dawn.com': false,
    'mail2dc.com': false,
    'mail2dealer.com': false,
    'mail2deanna.com': false,
    'mail2dearest.com': false,
    'mail2debbie.com': false,
    'mail2debby.com': false,
    'mail2deer.com': false,
    'mail2delaware.com': false,
    'mail2delicious.com': false,
    'mail2demeter.com': false,
    'mail2democrat.com': false,
    'mail2denise.com': false,
    'mail2denmark.com': false,
    'mail2dennis.com': false,
    'mail2dentist.com': false,
    'mail2derek.com': false,
    'mail2desert.com': false,
    'mail2devoted.com': false,
    'mail2devotion.com': false,
    'mail2diamond.com': false,
    'mail2diana.com': false,
    'mail2diane.com': false,
    'mail2diehard.com': false,
    'mail2dilemma.com': false,
    'mail2dillon.com': false,
    'mail2dinner.com': false,
    'mail2dinosaur.com': false,
    'mail2dionysos.com': false,
    'mail2diplomat.com': false,
    'mail2director.com': false,
    'mail2dirk.com': false,
    'mail2disco.com': false,
    'mail2dive.com': false,
    'mail2diver.com': false,
    'mail2divorced.com': false,
    'mail2djibouti.com': false,
    'mail2doctor.com': false,
    'mail2doglover.com': false,
    'mail2dominic.com': false,
    'mail2dominica.com': false,
    'mail2dominicanrepublic.com': false,
    'mail2don.com': false,
    'mail2donald.com': false,
    'mail2donna.com': false,
    'mail2doris.com': false,
    'mail2dorothy.com': false,
    'mail2doug.com': false,
    'mail2dough.com': false,
    'mail2douglas.com': false,
    'mail2dow.com': false,
    'mail2downtown.com': false,
    'mail2dream.com': false,
    'mail2dreamer.com': false,
    'mail2dude.com': false,
    'mail2dustin.com': false,
    'mail2dyke.com': false,
    'mail2dylan.com': false,
    'mail2earl.com': false,
    'mail2earth.com': false,
    'mail2eastend.com': false,
    'mail2eat.com': false,
    'mail2economist.com': false,
    'mail2ecuador.com': false,
    'mail2eddie.com': false,
    'mail2edgar.com': false,
    'mail2edwin.com': false,
    'mail2egypt.com': false,
    'mail2electron.com': false,
    'mail2eli.com': false,
    'mail2elizabeth.com': false,
    'mail2ellen.com': false,
    'mail2elliot.com': false,
    'mail2elsalvador.com': false,
    'mail2elvis.com': false,
    'mail2emergency.com': false,
    'mail2emily.com': false,
    'mail2engineer.com': false,
    'mail2english.com': false,
    'mail2environmentalist.com': false,
    'mail2eos.com': false,
    'mail2eric.com': false,
    'mail2erica.com': false,
    'mail2erin.com': false,
    'mail2erinyes.com': false,
    'mail2eris.com': false,
    'mail2eritrea.com': false,
    'mail2ernie.com': false,
    'mail2eros.com': false,
    'mail2estonia.com': false,
    'mail2ethan.com': false,
    'mail2ethiopia.com': false,
    'mail2eu.com': false,
    'mail2europe.com': false,
    'mail2eurus.com': false,
    'mail2eva.com': false,
    'mail2evan.com': false,
    'mail2evelyn.com': false,
    'mail2everything.com': false,
    'mail2exciting.com': false,
    'mail2expert.com': false,
    'mail2fairy.com': false,
    'mail2faith.com': false,
    'mail2fanatic.com': false,
    'mail2fancy.com': false,
    'mail2fantasy.com': false,
    'mail2farm.com': false,
    'mail2farmer.com': false,
    'mail2fashion.com': false,
    'mail2fat.com': false,
    'mail2feeling.com': false,
    'mail2female.com': false,
    'mail2fever.com': false,
    'mail2fighter.com': false,
    'mail2fiji.com': false,
    'mail2filmfestival.com': false,
    'mail2films.com': false,
    'mail2finance.com': false,
    'mail2finland.com': false,
    'mail2fireman.com': false,
    'mail2firm.com': false,
    'mail2fisherman.com': false,
    'mail2flexible.com': false,
    'mail2florence.com': false,
    'mail2florida.com': false,
    'mail2floyd.com': false,
    'mail2fly.com': false,
    'mail2fond.com': false,
    'mail2fondness.com': false,
    'mail2football.com': false,
    'mail2footballfan.com': false,
    'mail2found.com': false,
    'mail2france.com': false,
    'mail2frank.com': false,
    'mail2frankfurt.com': false,
    'mail2franklin.com': false,
    'mail2fred.com': false,
    'mail2freddie.com': false,
    'mail2free.com': false,
    'mail2freedom.com': false,
    'mail2french.com': false,
    'mail2freudian.com': false,
    'mail2friendship.com': false,
    'mail2from.com': false,
    'mail2fun.com': false,
    'mail2gabon.com': false,
    'mail2gabriel.com': false,
    'mail2gail.com': false,
    'mail2galaxy.com': false,
    'mail2gambia.com': false,
    'mail2games.com': false,
    'mail2gary.com': false,
    'mail2gavin.com': false,
    'mail2gemini.com': false,
    'mail2gene.com': false,
    'mail2genes.com': false,
    'mail2geneva.com': false,
    'mail2george.com': false,
    'mail2georgia.com': false,
    'mail2gerald.com': false,
    'mail2german.com': false,
    'mail2germany.com': false,
    'mail2ghana.com': false,
    'mail2gilbert.com': false,
    'mail2gina.com': false,
    'mail2girl.com': false,
    'mail2glen.com': false,
    'mail2gloria.com': false,
    'mail2goddess.com': false,
    'mail2gold.com': false,
    'mail2golfclub.com': false,
    'mail2golfer.com': false,
    'mail2gordon.com': false,
    'mail2government.com': false,
    'mail2grab.com': false,
    'mail2grace.com': false,
    'mail2graham.com': false,
    'mail2grandma.com': false,
    'mail2grandpa.com': false,
    'mail2grant.com': false,
    'mail2greece.com': false,
    'mail2green.com': false,
    'mail2greg.com': false,
    'mail2grenada.com': false,
    'mail2gsm.com': false,
    'mail2guard.com': false,
    'mail2guatemala.com': false,
    'mail2guy.com': false,
    'mail2hades.com': false,
    'mail2haiti.com': false,
    'mail2hal.com': false,
    'mail2handhelds.com': false,
    'mail2hank.com': false,
    'mail2hannah.com': false,
    'mail2harold.com': false,
    'mail2harry.com': false,
    'mail2hawaii.com': false,
    'mail2headhunter.com': false,
    'mail2heal.com': false,
    'mail2heather.com': false,
    'mail2heaven.com': false,
    'mail2hebe.com': false,
    'mail2hecate.com': false,
    'mail2heidi.com': false,
    'mail2helen.com': false,
    'mail2hell.com': false,
    'mail2help.com': false,
    'mail2helpdesk.com': false,
    'mail2henry.com': false,
    'mail2hephaestus.com': false,
    'mail2hera.com': false,
    'mail2hercules.com': false,
    'mail2herman.com': false,
    'mail2hermes.com': false,
    'mail2hespera.com': false,
    'mail2hestia.com': false,
    'mail2highschool.com': false,
    'mail2hindu.com': false,
    'mail2hip.com': false,
    'mail2hiphop.com': false,
    'mail2holland.com': false,
    'mail2holly.com': false,
    'mail2hollywood.com': false,
    'mail2homer.com': false,
    'mail2honduras.com': false,
    'mail2honey.com': false,
    'mail2hongkong.com': false,
    'mail2hope.com': false,
    'mail2horse.com': false,
    'mail2hot.com': false,
    'mail2hotel.com': false,
    'mail2houston.com': false,
    'mail2howard.com': false,
    'mail2hugh.com': false,
    'mail2human.com': false,
    'mail2hungary.com': false,
    'mail2hungry.com': false,
    'mail2hygeia.com': false,
    'mail2hyperspace.com': false,
    'mail2hypnos.com': false,
    'mail2ian.com': false,
    'mail2ice-cream.com': false,
    'mail2iceland.com': false,
    'mail2idaho.com': false,
    'mail2idontknow.com': false,
    'mail2illinois.com': false,
    'mail2imam.com': false,
    'mail2in.com': false,
    'mail2india.com': false,
    'mail2indian.com': false,
    'mail2indiana.com': false,
    'mail2indonesia.com': false,
    'mail2infinity.com': false,
    'mail2intense.com': false,
    'mail2iowa.com': false,
    'mail2iran.com': false,
    'mail2iraq.com': false,
    'mail2ireland.com': false,
    'mail2irene.com': false,
    'mail2iris.com': false,
    'mail2irresistible.com': false,
    'mail2irving.com': false,
    'mail2irwin.com': false,
    'mail2isaac.com': false,
    'mail2israel.com': false,
    'mail2italian.com': false,
    'mail2italy.com': false,
    'mail2jackie.com': false,
    'mail2jacob.com': false,
    'mail2jail.com': false,
    'mail2jaime.com': false,
    'mail2jake.com': false,
    'mail2jamaica.com': false,
    'mail2james.com': false,
    'mail2jamie.com': false,
    'mail2jan.com': false,
    'mail2jane.com': false,
    'mail2janet.com': false,
    'mail2janice.com': false,
    'mail2japan.com': false,
    'mail2japanese.com': false,
    'mail2jasmine.com': false,
    'mail2jason.com': false,
    'mail2java.com': false,
    'mail2jay.com': false,
    'mail2jazz.com': false,
    'mail2jed.com': false,
    'mail2jeffrey.com': false,
    'mail2jennifer.com': false,
    'mail2jenny.com': false,
    'mail2jeremy.com': false,
    'mail2jerry.com': false,
    'mail2jessica.com': false,
    'mail2jessie.com': false,
    'mail2jesus.com': false,
    'mail2jew.com': false,
    'mail2jeweler.com': false,
    'mail2jim.com': false,
    'mail2jimmy.com': false,
    'mail2joan.com': false,
    'mail2joann.com': false,
    'mail2joanna.com': false,
    'mail2jody.com': false,
    'mail2joe.com': false,
    'mail2joel.com': false,
    'mail2joey.com': false,
    'mail2john.com': false,
    'mail2join.com': false,
    'mail2jon.com': false,
    'mail2jonathan.com': false,
    'mail2jones.com': false,
    'mail2jordan.com': false,
    'mail2joseph.com': false,
    'mail2josh.com': false,
    'mail2joy.com': false,
    'mail2juan.com': false,
    'mail2judge.com': false,
    'mail2judy.com': false,
    'mail2juggler.com': false,
    'mail2julian.com': false,
    'mail2julie.com': false,
    'mail2jumbo.com': false,
    'mail2junk.com': false,
    'mail2justin.com': false,
    'mail2justme.com': false,
    'mail2kansas.com': false,
    'mail2karate.com': false,
    'mail2karen.com': false,
    'mail2karl.com': false,
    'mail2karma.com': false,
    'mail2kathleen.com': false,
    'mail2kathy.com': false,
    'mail2katie.com': false,
    'mail2kay.com': false,
    'mail2kazakhstan.com': false,
    'mail2keen.com': false,
    'mail2keith.com': false,
    'mail2kelly.com': false,
    'mail2kelsey.com': false,
    'mail2ken.com': false,
    'mail2kendall.com': false,
    'mail2kennedy.com': false,
    'mail2kenneth.com': false,
    'mail2kenny.com': false,
    'mail2kentucky.com': false,
    'mail2kenya.com': false,
    'mail2kerry.com': false,
    'mail2kevin.com': false,
    'mail2kim.com': false,
    'mail2kimberly.com': false,
    'mail2king.com': false,
    'mail2kirk.com': false,
    'mail2kiss.com': false,
    'mail2kosher.com': false,
    'mail2kristin.com': false,
    'mail2kurt.com': false,
    'mail2kuwait.com': false,
    'mail2kyle.com': false,
    'mail2kyrgyzstan.com': false,
    'mail2la.com': false,
    'mail2lacrosse.com': false,
    'mail2lance.com': false,
    'mail2lao.com': false,
    'mail2larry.com': false,
    'mail2latvia.com': false,
    'mail2laugh.com': false,
    'mail2laura.com': false,
    'mail2lauren.com': false,
    'mail2laurie.com': false,
    'mail2lawrence.com': false,
    'mail2lawyer.com': false,
    'mail2lebanon.com': false,
    'mail2lee.com': false,
    'mail2leo.com': false,
    'mail2leon.com': false,
    'mail2leonard.com': false,
    'mail2leone.com': false,
    'mail2leslie.com': false,
    'mail2letter.com': false,
    'mail2liberia.com': false,
    'mail2libertarian.com': false,
    'mail2libra.com': false,
    'mail2libya.com': false,
    'mail2liechtenstein.com': false,
    'mail2life.com': false,
    'mail2linda.com': false,
    'mail2linux.com': false,
    'mail2lionel.com': false,
    'mail2lipstick.com': false,
    'mail2liquid.com': false,
    'mail2lisa.com': false,
    'mail2lithuania.com': false,
    'mail2litigator.com': false,
    'mail2liz.com': false,
    'mail2lloyd.com': false,
    'mail2lois.com': false,
    'mail2lola.com': false,
    'mail2london.com': false,
    'mail2looking.com': false,
    'mail2lori.com': false,
    'mail2lost.com': false,
    'mail2lou.com': false,
    'mail2louis.com': false,
    'mail2louisiana.com': false,
    'mail2lovable.com': false,
    'mail2love.com': false,
    'mail2lucky.com': false,
    'mail2lucy.com': false,
    'mail2lunch.com': false,
    'mail2lust.com': false,
    'mail2luxembourg.com': false,
    'mail2luxury.com': false,
    'mail2lyle.com': false,
    'mail2lynn.com': false,
    'mail2madagascar.com': false,
    'mail2madison.com': false,
    'mail2madrid.com': false,
    'mail2maggie.com': false,
    'mail2mail4.com': false,
    'mail2maine.com': false,
    'mail2malawi.com': false,
    'mail2malaysia.com': false,
    'mail2maldives.com': false,
    'mail2mali.com': false,
    'mail2malta.com': false,
    'mail2mambo.com': false,
    'mail2man.com': false,
    'mail2mandy.com': false,
    'mail2manhunter.com': false,
    'mail2mankind.com': false,
    'mail2many.com': false,
    'mail2marc.com': false,
    'mail2marcia.com': false,
    'mail2margaret.com': false,
    'mail2margie.com': false,
    'mail2marhaba.com': false,
    'mail2maria.com': false,
    'mail2marilyn.com': false,
    'mail2marines.com': false,
    'mail2mark.com': false,
    'mail2marriage.com': false,
    'mail2married.com': false,
    'mail2marries.com': false,
    'mail2mars.com': false,
    'mail2marsha.com': false,
    'mail2marshallislands.com': false,
    'mail2martha.com': false,
    'mail2martin.com': false,
    'mail2marty.com': false,
    'mail2marvin.com': false,
    'mail2mary.com': false,
    'mail2maryland.com': false,
    'mail2mason.com': false,
    'mail2massachusetts.com': false,
    'mail2matt.com': false,
    'mail2matthew.com': false,
    'mail2maurice.com': false,
    'mail2mauritania.com': false,
    'mail2mauritius.com': false,
    'mail2max.com': false,
    'mail2maxwell.com': false,
    'mail2maybe.com': false,
    'mail2mba.com': false,
    'mail2me4u.com': false,
    'mail2mechanic.com': false,
    'mail2medieval.com': false,
    'mail2megan.com': false,
    'mail2mel.com': false,
    'mail2melanie.com': false,
    'mail2melissa.com': false,
    'mail2melody.com': false,
    'mail2member.com': false,
    'mail2memphis.com': false,
    'mail2methodist.com': false,
    'mail2mexican.com': false,
    'mail2mexico.com': false,
    'mail2mgz.com': false,
    'mail2miami.com': false,
    'mail2michael.com': false,
    'mail2michelle.com': false,
    'mail2michigan.com': false,
    'mail2mike.com': false,
    'mail2milan.com': false,
    'mail2milano.com': false,
    'mail2mildred.com': false,
    'mail2milkyway.com': false,
    'mail2millennium.com': false,
    'mail2millionaire.com': false,
    'mail2milton.com': false,
    'mail2mime.com': false,
    'mail2mindreader.com': false,
    'mail2mini.com': false,
    'mail2minister.com': false,
    'mail2minneapolis.com': false,
    'mail2minnesota.com': false,
    'mail2miracle.com': false,
    'mail2missionary.com': false,
    'mail2mississippi.com': false,
    'mail2missouri.com': false,
    'mail2mitch.com': false,
    'mail2model.com': false,
    'mail2moldova.commail2molly.com': false,
    'mail2mom.com': false,
    'mail2monaco.com': false,
    'mail2money.com': false,
    'mail2mongolia.com': false,
    'mail2monica.com': false,
    'mail2montana.com': false,
    'mail2monty.com': false,
    'mail2moon.com': false,
    'mail2morocco.com': false,
    'mail2morpheus.com': false,
    'mail2mors.com': false,
    'mail2moscow.com': false,
    'mail2moslem.com': false,
    'mail2mouseketeer.com': false,
    'mail2movies.com': false,
    'mail2mozambique.com': false,
    'mail2mp3.com': false,
    'mail2mrright.com': false,
    'mail2msright.com': false,
    'mail2museum.com': false,
    'mail2music.com': false,
    'mail2musician.com': false,
    'mail2muslim.com': false,
    'mail2my.com': false,
    'mail2myboat.com': false,
    'mail2mycar.com': false,
    'mail2mycell.com': false,
    'mail2mygsm.com': false,
    'mail2mylaptop.com': false,
    'mail2mymac.com': false,
    'mail2mypager.com': false,
    'mail2mypalm.com': false,
    'mail2mypc.com': false,
    'mail2myphone.com': false,
    'mail2myplane.com': false,
    'mail2namibia.com': false,
    'mail2nancy.com': false,
    'mail2nasdaq.com': false,
    'mail2nathan.com': false,
    'mail2nauru.com': false,
    'mail2navy.com': false,
    'mail2neal.com': false,
    'mail2nebraska.com': false,
    'mail2ned.com': false,
    'mail2neil.com': false,
    'mail2nelson.com': false,
    'mail2nemesis.com': false,
    'mail2nepal.com': false,
    'mail2netherlands.com': false,
    'mail2network.com': false,
    'mail2nevada.com': false,
    'mail2newhampshire.com': false,
    'mail2newjersey.com': false,
    'mail2newmexico.com': false,
    'mail2newyork.com': false,
    'mail2newzealand.com': false,
    'mail2nicaragua.com': false,
    'mail2nick.com': false,
    'mail2nicole.com': false,
    'mail2niger.com': false,
    'mail2nigeria.com': false,
    'mail2nike.com': false,
    'mail2no.com': false,
    'mail2noah.com': false,
    'mail2noel.com': false,
    'mail2noelle.com': false,
    'mail2normal.com': false,
    'mail2norman.com': false,
    'mail2northamerica.com': false,
    'mail2northcarolina.com': false,
    'mail2northdakota.com': false,
    'mail2northpole.com': false,
    'mail2norway.com': false,
    'mail2notus.com': false,
    'mail2noway.com': false,
    'mail2nowhere.com': false,
    'mail2nuclear.com': false,
    'mail2nun.com': false,
    'mail2ny.com': false,
    'mail2oasis.com': false,
    'mail2oceanographer.com': false,
    'mail2ohio.com': false,
    'mail2ok.com': false,
    'mail2oklahoma.com': false,
    'mail2oliver.com': false,
    'mail2oman.com': false,
    'mail2one.com': false,
    'mail2onfire.com': false,
    'mail2online.com': false,
    'mail2oops.com': false,
    'mail2open.com': false,
    'mail2ophthalmologist.com': false,
    'mail2optometrist.com': false,
    'mail2oregon.com': false,
    'mail2oscars.com': false,
    'mail2oslo.com': false,
    'mail2painter.com': false,
    'mail2pakistan.com': false,
    'mail2palau.com': false,
    'mail2pan.com': false,
    'mail2panama.com': false,
    'mail2paraguay.com': false,
    'mail2paralegal.com': false,
    'mail2paris.com': false,
    'mail2park.com': false,
    'mail2parker.com': false,
    'mail2party.com': false,
    'mail2passion.com': false,
    'mail2pat.com': false,
    'mail2patricia.com': false,
    'mail2patrick.com': false,
    'mail2patty.com': false,
    'mail2paul.com': false,
    'mail2paula.com': false,
    'mail2pay.com': false,
    'mail2peace.com': false,
    'mail2pediatrician.com': false,
    'mail2peggy.com': false,
    'mail2pennsylvania.com': false,
    'mail2perry.com': false,
    'mail2persephone.com': false,
    'mail2persian.com': false,
    'mail2peru.com': false,
    'mail2pete.com': false,
    'mail2peter.com': false,
    'mail2pharmacist.com': false,
    'mail2phil.com': false,
    'mail2philippines.com': false,
    'mail2phoenix.com': false,
    'mail2phonecall.com': false,
    'mail2phyllis.com': false,
    'mail2pickup.com': false,
    'mail2pilot.com': false,
    'mail2pisces.com': false,
    'mail2planet.com': false,
    'mail2platinum.com': false,
    'mail2plato.com': false,
    'mail2pluto.com': false,
    'mail2pm.com': false,
    'mail2podiatrist.com': false,
    'mail2poet.com': false,
    'mail2poland.com': false,
    'mail2policeman.com': false,
    'mail2policewoman.com': false,
    'mail2politician.com': false,
    'mail2pop.com': false,
    'mail2pope.com': false,
    'mail2popular.com': false,
    'mail2portugal.com': false,
    'mail2poseidon.com': false,
    'mail2potatohead.com': false,
    'mail2power.com': false,
    'mail2presbyterian.com': false,
    'mail2president.com': false,
    'mail2priest.com': false,
    'mail2prince.com': false,
    'mail2princess.com': false,
    'mail2producer.com': false,
    'mail2professor.com': false,
    'mail2protect.com': false,
    'mail2psychiatrist.com': false,
    'mail2psycho.com': false,
    'mail2psychologist.com': false,
    'mail2qatar.com': false,
    'mail2queen.com': false,
    'mail2rabbi.com': false,
    'mail2race.com': false,
    'mail2racer.com': false,
    'mail2rachel.com': false,
    'mail2rage.com': false,
    'mail2rainmaker.com': false,
    'mail2ralph.com': false,
    'mail2randy.com': false,
    'mail2rap.com': false,
    'mail2rare.com': false,
    'mail2rave.com': false,
    'mail2ray.com': false,
    'mail2raymond.com': false,
    'mail2realtor.com': false,
    'mail2rebecca.com': false,
    'mail2recruiter.com': false,
    'mail2recycle.com': false,
    'mail2redhead.com': false,
    'mail2reed.com': false,
    'mail2reggie.com': false,
    'mail2register.com': false,
    'mail2rent.com': false,
    'mail2republican.com': false,
    'mail2resort.com': false,
    'mail2rex.com': false,
    'mail2rhodeisland.com': false,
    'mail2rich.com': false,
    'mail2richard.com': false,
    'mail2ricky.com': false,
    'mail2ride.com': false,
    'mail2riley.com': false,
    'mail2rita.com': false,
    'mail2rob.com': false,
    'mail2robert.com': false,
    'mail2roberta.com': false,
    'mail2robin.com': false,
    'mail2rock.com': false,
    'mail2rocker.com': false,
    'mail2rod.com': false,
    'mail2rodney.com': false,
    'mail2romania.com': false,
    'mail2rome.com': false,
    'mail2ron.com': false,
    'mail2ronald.com': false,
    'mail2ronnie.com': false,
    'mail2rose.com': false,
    'mail2rosie.com': false,
    'mail2roy.com': false,
    'mail2rudy.com': false,
    'mail2rugby.com': false,
    'mail2runner.com': false,
    'mail2russell.com': false,
    'mail2russia.com': false,
    'mail2russian.com': false,
    'mail2rusty.com': false,
    'mail2ruth.com': false,
    'mail2rwanda.com': false,
    'mail2ryan.com': false,
    'mail2sa.com': false,
    'mail2sabrina.com': false,
    'mail2safe.com': false,
    'mail2sagittarius.com': false,
    'mail2sail.com': false,
    'mail2sailor.com': false,
    'mail2sal.com': false,
    'mail2salaam.com': false,
    'mail2sam.com': false,
    'mail2samantha.com': false,
    'mail2samoa.com': false,
    'mail2samurai.com': false,
    'mail2sandra.com': false,
    'mail2sandy.com': false,
    'mail2sanfrancisco.com': false,
    'mail2sanmarino.com': false,
    'mail2santa.com': false,
    'mail2sara.com': false,
    'mail2sarah.com': false,
    'mail2sat.com': false,
    'mail2saturn.com': false,
    'mail2saudi.com': false,
    'mail2saudiarabia.com': false,
    'mail2save.com': false,
    'mail2savings.com': false,
    'mail2school.com': false,
    'mail2scientist.com': false,
    'mail2scorpio.com': false,
    'mail2scott.com': false,
    'mail2sean.com': false,
    'mail2search.com': false,
    'mail2seattle.com': false,
    'mail2secretagent.com': false,
    'mail2senate.com': false,
    'mail2senegal.com': false,
    'mail2sensual.com': false,
    'mail2seth.com': false,
    'mail2sevenseas.com': false,
    'mail2sexy.com': false,
    'mail2seychelles.com': false,
    'mail2shane.com': false,
    'mail2sharon.com': false,
    'mail2shawn.com': false,
    'mail2ship.com': false,
    'mail2shirley.com': false,
    'mail2shoot.com': false,
    'mail2shuttle.com': false,
    'mail2sierraleone.com': false,
    'mail2simon.com': false,
    'mail2singapore.com': false,
    'mail2single.com': false,
    'mail2site.com': false,
    'mail2skater.com': false,
    'mail2skier.com': false,
    'mail2sky.com': false,
    'mail2sleek.com': false,
    'mail2slim.com': false,
    'mail2slovakia.com': false,
    'mail2slovenia.com': false,
    'mail2smile.com': false,
    'mail2smith.com': false,
    'mail2smooth.com': false,
    'mail2soccer.com': false,
    'mail2soccerfan.com': false,
    'mail2socialist.com': false,
    'mail2soldier.com': false,
    'mail2somalia.com': false,
    'mail2son.com': false,
    'mail2song.com': false,
    'mail2sos.com': false,
    'mail2sound.com': false,
    'mail2southafrica.com': false,
    'mail2southamerica.com': false,
    'mail2southcarolina.com': false,
    'mail2southdakota.com': false,
    'mail2southkorea.com': false,
    'mail2southpole.com': false,
    'mail2spain.com': false,
    'mail2spanish.com': false,
    'mail2spare.com': false,
    'mail2spectrum.com': false,
    'mail2splash.com': false,
    'mail2sponsor.com': false,
    'mail2sports.com': false,
    'mail2srilanka.com': false,
    'mail2stacy.com': false,
    'mail2stan.com': false,
    'mail2stanley.com': false,
    'mail2star.com': false,
    'mail2state.com': false,
    'mail2stephanie.com': false,
    'mail2steve.com': false,
    'mail2steven.com': false,
    'mail2stewart.com': false,
    'mail2stlouis.com': false,
    'mail2stock.com': false,
    'mail2stockholm.com': false,
    'mail2stockmarket.com': false,
    'mail2storage.com': false,
    'mail2store.com': false,
    'mail2strong.com': false,
    'mail2student.com': false,
    'mail2studio.com': false,
    'mail2studio54.com': false,
    'mail2stuntman.com': false,
    'mail2subscribe.com': false,
    'mail2sudan.com': false,
    'mail2superstar.com': false,
    'mail2surfer.com': false,
    'mail2suriname.com': false,
    'mail2susan.com': false,
    'mail2suzie.com': false,
    'mail2swaziland.com': false,
    'mail2sweden.com': false,
    'mail2sweetheart.com': false,
    'mail2swim.com': false,
    'mail2swimmer.com': false,
    'mail2swiss.com': false,
    'mail2switzerland.com': false,
    'mail2sydney.com': false,
    'mail2sylvia.com': false,
    'mail2syria.com': false,
    'mail2taboo.com': false,
    'mail2taiwan.com': false,
    'mail2tajikistan.com': false,
    'mail2tammy.com': false,
    'mail2tango.com': false,
    'mail2tanya.com': false,
    'mail2tanzania.com': false,
    'mail2tara.com': false,
    'mail2taurus.com': false,
    'mail2taxi.com': false,
    'mail2taxidermist.com': false,
    'mail2taylor.com': false,
    'mail2taz.com': false,
    'mail2teacher.com': false,
    'mail2technician.com': false,
    'mail2ted.com': false,
    'mail2telephone.com': false,
    'mail2teletubbie.com': false,
    'mail2tenderness.com': false,
    'mail2tennessee.com': false,
    'mail2tennis.com': false,
    'mail2tennisfan.com': false,
    'mail2terri.com': false,
    'mail2terry.com': false,
    'mail2test.com': false,
    'mail2texas.com': false,
    'mail2thailand.com': false,
    'mail2therapy.com': false,
    'mail2think.com': false,
    'mail2tickets.com': false,
    'mail2tiffany.com': false,
    'mail2tim.com': false,
    'mail2time.com': false,
    'mail2timothy.com': false,
    'mail2tina.com': false,
    'mail2titanic.com': false,
    'mail2toby.com': false,
    'mail2todd.com': false,
    'mail2togo.com': false,
    'mail2tom.com': false,
    'mail2tommy.com': false,
    'mail2tonga.com': false,
    'mail2tony.com': false,
    'mail2touch.com': false,
    'mail2tourist.com': false,
    'mail2tracey.com': false,
    'mail2tracy.com': false,
    'mail2tramp.com': false,
    'mail2travel.com': false,
    'mail2traveler.com': false,
    'mail2travis.com': false,
    'mail2trekkie.com': false,
    'mail2trex.com': false,
    'mail2triallawyer.com': false,
    'mail2trick.com': false,
    'mail2trillionaire.com': false,
    'mail2troy.com': false,
    'mail2truck.com': false,
    'mail2trump.com': false,
    'mail2try.com': false,
    'mail2tunisia.com': false,
    'mail2turbo.com': false,
    'mail2turkey.com': false,
    'mail2turkmenistan.com': false,
    'mail2tv.com': false,
    'mail2tycoon.com': false,
    'mail2tyler.com': false,
    'mail2u4me.com': false,
    'mail2uae.com': false,
    'mail2uganda.com': false,
    'mail2uk.com': false,
    'mail2ukraine.com': false,
    'mail2uncle.com': false,
    'mail2unsubscribe.com': false,
    'mail2uptown.com': false,
    'mail2uruguay.com': false,
    'mail2usa.com': false,
    'mail2utah.com': false,
    'mail2uzbekistan.com': false,
    'mail2v.com': false,
    'mail2vacation.com': false,
    'mail2valentines.com': false,
    'mail2valerie.com': false,
    'mail2valley.com': false,
    'mail2vamoose.com': false,
    'mail2vanessa.com': false,
    'mail2vanuatu.com': false,
    'mail2venezuela.com': false,
    'mail2venous.com': false,
    'mail2venus.com': false,
    'mail2vermont.com': false,
    'mail2vickie.com': false,
    'mail2victor.com': false,
    'mail2victoria.com': false,
    'mail2vienna.com': false,
    'mail2vietnam.com': false,
    'mail2vince.com': false,
    'mail2virginia.com': false,
    'mail2virgo.com': false,
    'mail2visionary.com': false,
    'mail2vodka.com': false,
    'mail2volleyball.com': false,
    'mail2waiter.com': false,
    'mail2wallstreet.com': false,
    'mail2wally.com': false,
    'mail2walter.com': false,
    'mail2warren.com': false,
    'mail2washington.com': false,
    'mail2wave.com': false,
    'mail2way.com': false,
    'mail2waycool.com': false,
    'mail2wayne.com': false,
    'mail2webmaster.com': false,
    'mail2webtop.com': false,
    'mail2webtv.com': false,
    'mail2weird.com': false,
    'mail2wendell.com': false,
    'mail2wendy.com': false,
    'mail2westend.com': false,
    'mail2westvirginia.com': false,
    'mail2whether.com': false,
    'mail2whip.com': false,
    'mail2white.com': false,
    'mail2whitehouse.com': false,
    'mail2whitney.com': false,
    'mail2why.com': false,
    'mail2wilbur.com': false,
    'mail2wild.com': false,
    'mail2willard.com': false,
    'mail2willie.com': false,
    'mail2wine.com': false,
    'mail2winner.com': false,
    'mail2wired.com': false,
    'mail2wisconsin.com': false,
    'mail2woman.com': false,
    'mail2wonder.com': false,
    'mail2world.com': false,
    'mail2worship.com': false,
    'mail2wow.com': false,
    'mail2www.com': false,
    'mail2wyoming.com': false,
    'mail2xfiles.com': false,
    'mail2xox.com': false,
    'mail2yachtclub.com': false,
    'mail2yahalla.com': false,
    'mail2yemen.com': false,
    'mail2yes.com': false,
    'mail2yugoslavia.com': false,
    'mail2zack.com': false,
    'mail2zambia.com': false,
    'mail2zenith.com': false,
    'mail2zephir.com': false,
    'mail2zeus.com': false,
    'mail2zipper.com': false,
    'mail2zoo.com': false,
    'mail2zoologist.com': false,
    'mail2zurich.com': false,
    'mail3000.com': false,
    'mail333.com': false,
    'mailandftp.com': false,
    'mailandnews.com': false,
    'mailas.com': false,
    'mailasia.com': false,
    'mailbolt.com': false,
    'mailbomb.net': false,
    'mailboom.com': false,
    'mailbox.as': false,
    'mailbox.co.za': false,
    'mailbox.gr': false,
    'mailbox.hu': false,
    'mailbr.com.br': false,
    'mailc.net': false,
    'mailcan.com': false,
    'mailcc.com': false,
    'mailchoose.co': false,
    'mailcity.com': false,
    'mailclub.fr': false,
    'mailclub.net': false,
    'mailexcite.com': false,
    'mailforce.net': false,
    'mailftp.com': false,
    'mailgate.gr': false,
    'mailgenie.net': false,
    'mailhaven.com': false,
    'mailhood.com': false,
    'mailingweb.com': false,
    'mailisent.com': false,
    'mailite.com': false,
    'mailme.dk': false,
    'mailmight.com': false,
    'mailmij.nl': false,
    'mailnew.com': false,
    'mailops.com': false,
    'mailoye.com': false,
    'mailpanda.com': false,
    'mailpost.zzn.com': false,
    'mailpride.com': false,
    'mailpuppy.com': false,
    'mailroom.com': false,
    'mailru.com': false,
    'mailsent.net': false,
    'mailshuttle.com': false,
    'mailstart.com': false,
    'mailstartplus.com': false,
    'mailsurf.com': false,
    'mailtag.com': false,
    'mailto.de': false,
    'mailup.net': false,
    'mailwire.com': false,
    'maktoob.com': false,
    'malayalamtelevision.net': false,
    'manager.de': false,
    'mantrafreenet.com': false,
    'mantramail.com': false,
    'mantraonline.com': false,
    'marchmail.com': false,
    'mariah-carey.ml.org': false,
    'mariahc.com': false,
    'marijuana.nl': false,
    'marketing.lu': false,
    'married-not.com': false,
    'marsattack.com': false,
    'martindalemail.com': false,
    'masrawy.com': false,
    'matmail.com': false,
    'mauimail.com': false,
    'mauritius.com': false,
    'maxleft.com': false,
    'maxmail.co.uk': false,
    'mbox.com.au': false,
    'mchsi.com': false,
    'mciworldcom.net': false,
    'me-mail.hu': false,
    'me.com': false,
    'medical.net.au': false,
    'medione.net': false,
    'medmail.com': false,
    'medscape.com': false,
    'meetingmall.com': false,
    'megago.com': false,
    'megamail.pt': false,
    'megapathdsl.net': false,
    'megapoint.com': false,
    'mehrani.com': false,
    'mehtaweb.com': false,
    'mekhong.com': false,
    'melodymail.com': false,
    'meloo.com': false,
    'members.student.com': false,
    'message.hu': false,
    'messages.to': false,
    'metacrawler.com': false,
    'metalfan.com': false,
    'metta.lk': false,
    'miatadriver.com': false,
    'miesto.sk': false,
    'mighty.co.za': false,
    'miho-nakayama.com': false,
    'mikrotamanet.com': false,
    'millionaireintraining.com': false,
    'milmail.com': false,
    'mindless.com': false,
    'mindspring.com': false,
    'mini-mail.com': false,
    'misery.net': false,
    'mittalweb.com': false,
    'mixmail.com': false,
    'mjfrogmail.com': false,
    'ml1.net': false,
    'mobilbatam.com': false,
    'mochamail.com': false,
    'mohammed.com': false,
    'moldova.cc': false,
    'moldova.com': false,
    'moldovacc.com': false,
    'money.net': false,
    'montevideo.com.uy': false,
    'moonman.com': false,
    'moose-mail.com': false,
    'mortaza.com': false,
    'mosaicfx.com': false,
    'most-wanted.com': false,
    'mostlysunny.com': false,
    'motormania.com': false,
    'movemail.com': false,
    'movieluver.com': false,
    'mp4.it': false,
    'mr-potatohead.com': false,
    'mrpost.com': false,
    'mscold.com': false,
    'msgbox.com': false,
    'msn.com': false,
    'mttestdriver.com': false,
    'mundomail.net': false,
    'munich.com': false,
    'music.com': false,
    'musician.org': false,
    'musicscene.org': false,
    'mybox.it': false,
    'mycabin.com': false,
    'mycampus.com': false,
    'mycity.com': false,
    'mycool.com': false,
    'mydomain.com': false,
    'mydotcomaddress.com': false,
    'myfamily.com': false,
    'mygo.com': false,
    'myiris.com': false,
    'mynamedot.com': false,
    'mynetaddress.com': false,
    'myownemail.com': false,
    'myownfriends.com': false,
    'mypad.com': false,
    'mypersonalemail.com': false,
    'myplace.com': false,
    'myrealbox.com': false,
    'myremarq.com': false,
    'myself.com': false,
    'mystupidjob.com': false,
    'mythirdage.com': false,
    'myway.com': false,
    'myworldmail.com': false,
    'n2.com': false,
    'n2business.com': false,
    'n2mail.com': false,
    'n2software.com': false,
    'nabc.biz': false,
    'nafe.com': false,
    'nagpal.net': false,
    'nakedgreens.com': false,
    'name.com': false,
    'nameplanet.com': false,
    'nandomail.com': false,
    'naplesnews.net': false,
    'naseej.com': false,
    'nate.com': false,
    'nativestar.net': false,
    'nativeweb.net': false,
    'naui.net': false,
    'nauticom.net': false,
    'naver.com': false,
    'navigator.lv': false,
    'navy.org': false,
    'naz.com': false,
    'nchoicemail.com': false,
    'neeva.net': false,
    'nemra1.com': false,
    'nenter.com': false,
    'neo.rr.com': false,
    'nervhq.org': false,
    'net-pager.net': false,
    'net4b.pt': false,
    'net4you.at': false,
    'netbounce.com': false,
    'netbroadcaster.com': false,
    'netby.dk': false,
    'netcenter-vn.net': false,
    'netcom.ca': false,
    'netcom.com': false,
    'netcourrier.com': false,
    'netexecutive.com': false,
    'netexpressway.com': false,
    'netgenie.com': false,
    'netian.com': false,
    'netizen.com.ar': false,
    'netlane.com': false,
    'netlimit.com': false,
    'netmanor.com': false,
    'netmongol.com': false,
    'netnet.com.sg': false,
    'netpiper.com': false,
    'netposta.net': false,
    'netradiomail.com': false,
    'netralink.com': false,
    'netscape.com': false,
    'netscape.net': false,
    'netscapeonline.co.uk': false,
    'netsero.net': false,
    'netspeedway.com': false,
    'netsquare.com': false,
    'netster.com': false,
    'nettaxi.com': false,
    'netzero.com': false,
    'netzero.net': false,
    'neuf.fr': false,
    'newmail.com': false,
    'newmail.net': false,
    'newmail.ru': false,
    'newyork.com': false,
    'nexxmail.com': false,
    'nfmail.com': false,
    'nhmail.com': false,
    'nicebush.com': false,
    'nicegal.com': false,
    'nicholastse.net': false,
    'nicolastse.com': false,
    'nightmail.com': false,
    'nikopage.com': false,
    'nimail.com': false,
    'nirvanafan.com': false,
    'noavar.com': false,
    'norika-fujiwara.com': false,
    'norikomail.com': false,
    'northgates.net': false,
    'nospammail.net': false,
    'ntlworld.com': false,
    'ntscan.com': false,
    'ny.com': false,
    'nyc.com': false,
    'nycmail.com': false,
    'nzoomail.com': false,
    'o-tay.com': false,
    'o2.co.uk': false,
    'oaklandas-fan.com': false,
    'oceanfree.net': false,
    'oddpost.com': false,
    'odmail.com': false,
    'office-email.com': false,
    'officedomain.com': false,
    'offroadwarrior.com': false,
    'oi.com.br': false,
    'oicexchange.com': false,
    'okbank.com': false,
    'okhuman.com': false,
    'okmad.com': false,
    'okmagic.com': false,
    'okname.net': false,
    'okuk.com': false,
    'oldies1041.com': false,
    'oldies104mail.com': false,
    'ole.com': false,
    'olemail.com': false,
    'olg.com': false,
    'olympist.net': false,
    'omaninfo.com': false,
    'onebox.com': false,
    'onenet.com.ar': false,
    'onet.pl': false,
    'oninet.pt': false,
    'online.de': false,
    'online.ie': false,
    'onlinewiz.com': false,
    'onmilwaukee.com': false,
    'onobox.com': false,
    'onvillage.com': false,
    'operafan.com': false,
    'operamail.com': false,
    'optician.com': false,
    'optonline.net': false,
    'optusnet.com.au': false,
    'orange.fr': false,
    'orange.net': false,
    'orbitel.bg': false,
    'orgmail.net': false,
    'osite.com.br': false,
    'oso.com': false,
    'otakumail.com': false,
    'our-computer.com': false,
    'our-office.com': false,
    'our.st': false,
    'ourbrisbane.com': false,
    'ournet.md': false,
    'outel.com': false,
    'outgun.com': false,
    'outlook.com': false,
    'outlook.com.br': false,
    'over-the-rainbow.com': false,
    'ownmail.net': false,
    'ozbytes.net.au': false,
    'ozemail.com.au': false,
    'pacbell.net': false,
    'pacific-re.com': false,
    'packersfan.com': false,
    'pagina.de': false,
    'pagons.org': false,
    'pakistanoye.com': false,
    'palestinemail.com': false,
    'parkjiyoon.com': false,
    'parrot.com': false,
    'parsmail.com': false,
    'partlycloudy.com': false,
    'partynight.at': false,
    'parvazi.com': false,
    'passwordmail.com': false,
    'pathfindermail.com': false,
    'patmedia.net': false,
    'pconnections.net': false,
    'pcpostal.com': false,
    'pcsrock.com': false,
    'peachworld.com': false,
    'pediatrician.com': false,
    'pemail.net': false,
    'penpen.com': false,
    'peoplepc.com': false,
    'peopleweb.com': false,
    'perfectmail.com': false,
    'personal.ro': false,
    'personales.com': false,
    'petml.com': false,
    'pettypool.com': false,
    'pezeshkpour.com': false,
    'phayze.com': false,
    'phreaker.net': false,
    'pickupman.com': false,
    'picusnet.com': false,
    'pigpig.net': false,
    'pinoymail.com': false,
    'pipeline.com': false,
    'piracha.net': false,
    'pisem.net': false,
    'planet-mail.com': false,
    'planet.nl': false,
    'planetaccess.com': false,
    'planetall.com': false,
    'planetarymotion.net': false,
    'planetdirect.com': false,
    'planetearthinter.net': false,
    'planetout.com': false,
    'plasa.com': false,
    'playersodds.com': false,
    'playful.com': false,
    'plusmail.com.br': false,
    'pmail.net': false,
    'pobox.com': false,
    'pobox.hu': false,
    'pobox.sk': false,
    'pochta.ru': false,
    'poczta.fm': false,
    'poetic.com': false,
    'polbox.com': false,
    'policeoffice.com': false,
    'pool-sharks.com': false,
    'poond.com': false,
    'popaccount.com': false,
    'popmail.com': false,
    'popsmail.com': false,
    'popstar.com': false,
    'populus.net': false,
    'portableoffice.com': false,
    'portugalmail.com': false,
    'portugalmail.pt': false,
    'portugalnet.com': false,
    'positive-thinking.com': false,
    'post.com': false,
    'post.cz': false,
    'post.sk': false,
    'posta.net': false,
    'posta.ro': false,
    'postaccesslite.com': false,
    'postafree.com': false,
    'postaweb.com': false,
    'poste.it': false,
    'postinbox.com': false,
    'postino.ch': false,
    'postmark.net': false,
    'postmaster.co.uk': false,
    'postpro.net': false,
    'pousa.com': false,
    'powerfan.com': false,
    'praize.com': false,
    'premiumservice.com': false,
    'presidency.com': false,
    'press.co.jp': false,
    'priest.com': false,
    'primposta.com': false,
    'primposta.hu': false,
    'pro.hu': false,
    'probemail.com': false,
    'prodigy.net': false,
    'prodigy.net.mx': false,
    'progetplus.it': false,
    'programmer.net': false,
    'programozo.hu': false,
    'proinbox.com': false,
    'project2k.com': false,
    'prolaunch.com': false,
    'promessage.com': false,
    'prontomail.com': false,
    'protonmail.com': false,
    'psi.net': false,
    'psv-supporter.com': false,
    'ptd.net': false,
    'public.usa.com': false,
    'publicist.com': false,
    'pulp-fiction.com': false,
    'punkass.com': false,
    'qatarmail.com': false,
    'qis.net': false,
    'qprfans.com': false,
    'qq.com': false,
    'qrio.com': false,
    'quackquack.com': false,
    'quakemail.com': false,
    'qudsmail.com': false,
    'quepasa.com': false,
    'quickwebmail.com': false,
    'quiklinks.com': false,
    'quikmail.com': false,
    'qwest.net': false,
    'qwestoffice.net': false,
    'r-o-o-t.com': false,
    'r7.com': false,
    'raakim.com': false,
    'racedriver.com': false,
    'racefanz.com': false,
    'racingfan.com.au': false,
    'racingmail.com': false,
    'radicalz.com': false,
    'ragingbull.com': false,
    'rambler.ru': false,
    'ranmamail.com': false,
    'rastogi.net': false,
    'ratt-n-roll.com': false,
    'rattle-snake.com': false,
    'ravearena.com': false,
    'ravemail.com': false,
    'razormail.com': false,
    'rccgmail.org': false,
    'rcn.com': false,
    'realemail.net': false,
    'reallyfast.biz': false,
    'realradiomail.com': false,
    'recycler.com': false,
    'rediffmail.com': false,
    'rediffmailpro.com': false,
    'rednecks.com': false,
    'redseven.de': false,
    'redsfans.com': false,
    'reggafan.com': false,
    'registerednurses.com': false,
    'repairman.com': false,
    'reply.hu': false,
    'representative.com': false,
    'rescueteam.com': false,
    'resumemail.com': false,
    'rezai.com': false,
    'richmondhill.com': false,
    'rickymail.com': false,
    'rin.ru': false,
    'riopreto.com.br': false,
    'rn.com': false,
    'roadrunner.com': false,
    'roanokemail.com': false,
    'rock.com': false,
    'rocketmail.com': false,
    'rockfan.com': false,
    'rodrun.com': false,
    'rome.com': false,
    'roosh.com': false,
    'rotfl.com': false,
    'roughnet.com': false,
    'rr.com': false,
    'rrohio.com': false,
    'rsub.com': false,
    'rubyridge.com': false,
    'runbox.com': false,
    'rushpost.com': false,
    'ruttolibero.com': false,
    'rvshop.com': false,
    's-mail.com': false,
    'sabreshockey.com': false,
    'sacbeemail.com': false,
    'safarimail.com': false,
    'safe-mail.net': false,
    'sagra.lu': false,
    'sailormoon.com': false,
    'saintly.com': false,
    'saintmail.net': false,
    'sale-sale-sale.com': false,
    'salehi.net': false,
    'samerica.com': false,
    'samilan.net': false,
    'sammimail.com': false,
    'sanfranmail.com': false,
    'sanook.com': false,
    'sapo.pt': false,
    'sativa.ro.org': false,
    'saudia.com': false,
    'sayhi.net': false,
    'sbcglobal.net': false,
    'scandalmail.com': false,
    'schizo.com': false,
    'schoolemail.com': false,
    'schoolmail.com': false,
    'schoolsucks.com': false,
    'schweiz.org': false,
    'sci.fi': false,
    'science.com.au': false,
    'scientist.com': false,
    'scifianime.com': false,
    'scottishmail.co.uk': false,
    'scubadiving.com': false,
    'seanet.com': false,
    'searchwales.com': false,
    'sebil.com': false,
    'secret-police.com': false,
    'secretservices.net': false,
    'seductive.com': false,
    'seekstoyboy.com': false,
    'seguros.com.br': false,
    'send.hu': false,
    'sendme.cz': false,
    'sent.com': false,
    'sentrismail.com': false,
    'serga.com.ar': false,
    'servemymail.com': false,
    'sesmail.com': false,
    'sexmagnet.com': false,
    'seznam.cz': false,
    'sfr.fr': false,
    'shahweb.net': false,
    'shaniastuff.com': false,
    'sharewaredevelopers.com': false,
    'sharmaweb.com': false,
    'shaw.ca': false,
    'she.com': false,
    'shootmail.com': false,
    'shotgun.hu': false,
    'shuf.com': false,
    'sialkotcity.com': false,
    'sialkotian.com': false,
    'sialkotoye.com': false,
    'sify.com': false,
    'silkroad.net': false,
    'sina.cn': false,
    'sina.com': false,
    'sinamail.com': false,
    'singapore.com': false,
    'singmail.com': false,
    'singnet.com.sg': false,
    'singpost.com': false,
    'skafan.com': false,
    'skim.com': false,
    'skizo.hu': false,
    'sky.com': false,
    'skynet.be': false,
    'slamdunkfan.com': false,
    'slingshot.com': false,
    'slo.net': false,
    'slotter.com': false,
    'smapxsmap.net': false,
    'smileyface.comsmithemail.net': false,
    'smoothmail.com': false,
    'snail-mail.net': false,
    'snail-mail.ney': false,
    'snakemail.com': false,
    'sndt.net': false,
    'sneakemail.com': false,
    'snet.net': false,
    'snip.net': false,
    'sniper.hu': false,
    'snoopymail.com': false,
    'snowboarding.com': false,
    'snowdonia.net': false,
    'socamail.com': false,
    'socceramerica.net': false,
    'soccermail.com': false,
    'soccermomz.com': false,
    'sociologist.com': false,
    'softhome.net': false,
    'sol.dk': false,
    'soldier.hu': false,
    'soon.com': false,
    'soulfoodcookbook.com': false,
    'sp.nl': false,
    'space-bank.com': false,
    'space-man.com': false,
    'space-ship.com': false,
    'space-travel.com': false,
    'space.com': false,
    'spaceart.com': false,
    'spacebank.com': false,
    'spacemart.com': false,
    'spacetowns.com': false,
    'spacewar.com': false,
    'spamex.com': false,
    'spartapiet.com': false,
    'spazmail.com': false,
    'speedemail.net': false,
    'speedpost.net': false,
    'speedrules.com': false,
    'speedrulz.com': false,
    'speedy.com.ar': false,
    'spils.com': false,
    'spinfinder.com': false,
    'sportemail.com': false,
    'sportsmail.com': false,
    'sporttruckdriver.com': false,
    'spray.no': false,
    'spray.se': false,
    'sprintmail.com': false,
    'sprynet.com': false,
    'spymac.com': false,
    'srilankan.net': false,
    'st-davids.net': false,
    'stade.fr': false,
    'stalag13.com': false,
    'stargateradio.com': false,
    'starmail.com': false,
    'starmail.org': false,
    'starmedia.com': false,
    'starplace.com': false,
    'starpower.net': false,
    'starspath.com': false,
    'start.com.au': false,
    'starting-point.com': false,
    'startrekmail.com': false,
    'stealthmail.com': false,
    'stockracer.com': false,
    'stones.com': false,
    'stopdropandroll.com': false,
    'storksite.com': false,
    'stribmail.com': false,
    'strompost.com': false,
    'strongguy.com': false,
    'studentcenter.org': false,
    'subnetwork.com': false,
    'subram.com': false,
    'sudanmail.net': false,
    'suhabi.com': false,
    'suisse.org': false,
    'sukhumvit.net': false,
    'sunpoint.net': false,
    'sunrise-sunset.com': false,
    'sunsgame.com': false,
    'sunumail.sn': false,
    'superdada.com': false,
    'supereva.it': false,
    'supermail.ru': false,
    'surat.com': false,
    'surf3.net': false,
    'surfree.com': false,
    'surfy.net': false,
    'surimail.com': false,
    'survivormail.com': false,
    'swbell.net': false,
    'sweb.cz': false,
    'swiftdesk.com': false,
    'swingeasyhithard.com': false,
    'swingfan.com': false,
    'swipermail.zzn.com': false,
    'swirve.com': false,
    'swissinfo.org': false,
    'swissmail.net': false,
    'switchboardmail.com': false,
    'switzerland.org': false,
    'swva.net': false,
    'sx172.com': false,
    'sympatico.ca': false,
    'syom.com': false,
    'syriamail.com': false,
    't-online.de': false,
    't2mail.com': false,
    'takuyakimura.com': false,
    'talk21.com': false,
    'talkcity.com': false,
    'talktalk.co.uk': false,
    'tamil.com': false,
    'tampabay.rr.com': false,
    'tatanova.com': false,
    'tbwt.com': false,
    'tds.net': false,
    'teamdiscovery.com': false,
    'teamtulsa.net': false,
    'tech4peace.org': false,
    'techemail.com': false,
    'techie.com': false,
    'technisamail.co.za': false,
    'technologist.com': false,
    'techpointer.com': false,
    'techscout.com': false,
    'techseek.com': false,
    'techspot.com': false,
    'teenagedirtbag.com': false,
    'telebot.com': false,
    'telebot.net': false,
    'teleline.es': false,
    'telenet.be': false,
    'telerymd.com': false,
    'teleserve.dynip.com': false,
    'teletu.it': false,
    'telinco.net': false,
    'telkom.net': false,
    'telpage.net': false,
    'telus.net': false,
    'temtulsa.net': false,
    'tenchiclub.com': false,
    'tenderkiss.com': false,
    'tennismail.com': false,
    'terra.cl': false,
    'terra.com': false,
    'terra.com.ar': false,
    'terra.com.br': false,
    'terra.es': false,
    'tfanus.com.er': false,
    'tfz.net': false,
    'thai.com': false,
    'thaimail.com': false,
    'thaimail.net': false,
    'the-african.com': false,
    'the-airforce.com': false,
    'the-aliens.com': false,
    'the-american.com': false,
    'the-animal.com': false,
    'the-army.com': false,
    'the-astronaut.com': false,
    'the-beauty.com': false,
    'the-big-apple.com': false,
    'the-biker.com': false,
    'the-boss.com': false,
    'the-brazilian.com': false,
    'the-canadian.com': false,
    'the-canuck.com': false,
    'the-captain.com': false,
    'the-chinese.com': false,
    'the-country.com': false,
    'the-cowboy.com': false,
    'the-davis-home.com': false,
    'the-dutchman.com': false,
    'the-eagles.com': false,
    'the-englishman.com': false,
    'the-fastest.net': false,
    'the-fool.com': false,
    'the-frenchman.com': false,
    'the-galaxy.net': false,
    'the-genius.com': false,
    'the-gentleman.com': false,
    'the-german.com': false,
    'the-gremlin.com': false,
    'the-hooligan.com': false,
    'the-italian.com': false,
    'the-japanese.com': false,
    'the-lair.com': false,
    'the-madman.com': false,
    'the-mailinglist.com': false,
    'the-marine.com': false,
    'the-master.com': false,
    'the-mexican.com': false,
    'the-ministry.com': false,
    'the-monkey.com': false,
    'the-newsletter.net': false,
    'the-pentagon.com': false,
    'the-police.com': false,
    'the-prayer.com': false,
    'the-professional.com': false,
    'the-quickest.com': false,
    'the-russian.com': false,
    'the-snake.com': false,
    'the-spaceman.com': false,
    'the-stock-market.com': false,
    'the-student.net': false,
    'the-whitehouse.net': false,
    'the-wild-west.com': false,
    'the18th.com': false,
    'thecoolguy.com': false,
    'thecriminals.com': false,
    'thedoghousemail.com': false,
    'thedorm.com': false,
    'theend.hu': false,
    'theglobe.com': false,
    'thegolfcourse.com': false,
    'thegooner.com': false,
    'theheadoffice.com': false,
    'thelanddownunder.com': false,
    'themillionare.net': false,
    'theoffice.net': false,
    'thepokerface.com': false,
    'thepostmaster.net': false,
    'theraces.com': false,
    'theracetrack.com': false,
    'thestreetfighter.com': false,
    'theteebox.com': false,
    'thewatercooler.com': false,
    'thewebpros.co.uk': false,
    'thewizzard.com': false,
    'thewizzkid.com': false,
    'thezhangs.net': false,
    'thirdage.com': false,
    'thisgirl.com': false,
    'thoic.com': false,
    'thundermail.com': false,
    'tidni.com': false,
    'timein.net': false,
    'tin.it': false,
    'tiscali.at': false,
    'tiscali.be': false,
    'tiscali.co.uk': false,
    'tiscali.it': false,
    'tiscali.lu': false,
    'tiscali.se': false,
    'tkcity.com': false,
    'toolsource.com': false,
    'topchat.com': false,
    'topgamers.co.uk': false,
    'topletter.com': false,
    'topmail.com.ar': false,
    'topsurf.com': false,
    'topteam.bg': false,
    'torchmail.com': false,
    'tot.net': false,
    'totalmusic.net': false,
    'toughguy.net': false,
    'tpg.com.au': false,
    'travel.li': false,
    'trialbytrivia.com': false,
    'tritium.net': false,
    'trmailbox.com': false,
    'tropicalstorm.com': false,
    'truckers.com': false,
    'truckerz.com': false,
    'truckracer.com': false,
    'trust-me.com': false,
    'tsamail.co.za': false,
    'ttml.co.in': false,
    'tunisiamail.com': false,
    'turkey.com': false,
    'tvcablenet.be': false,
    'twinstarsmail.com': false,
    'tycoonmail.com': false,
    'typemail.com': false,
    'u2club.com': false,
    'uae.ac': false,
    'uaemail.com': false,
    'ubbi.com': false,
    'ubbi.com.br': false,
    'uboot.com': false,
    'uk2k.com': false,
    'uk2net.com': false,
    'uk7.net': false,
    'uk8.net': false,
    'ukbuilder.com': false,
    'ukcool.com': false,
    'ukdreamcast.com': false,
    'ukmail.org': false,
    'ukmax.com': false,
    'ukr.net': false,
    'uku.co.uk': false,
    'ultapulta.com': false,
    'ultrapostman.com': false,
    'ummah.org': false,
    'umpire.com': false,
    'unbounded.com': false,
    'unforgettable.com': false,
    'uni.de': false,
    'unican.es': false,
    'unihome.com': false,
    'universal.pt': false,
    'uno.ee': false,
    'uno.it': false,
    'unofree.it': false,
    'unomail.com': false,
    'uol.com.ar': false,
    'uol.com.br': false,
    'uol.com.co': false,
    'uol.com.mx': false,
    'uol.com.ve': false,
    'uole.com': false,
    'uole.com.ve': false,
    'uolmail.com': false,
    'uomail.com': false,
    'upf.org': false,
    'ureach.com': false,
    'urgentmail.biz': false,
    'usa.com': false,
    'usa.net': false,
    'usaaccess.net': false,
    'usanetmail.com': false,
    'usermail.com': false,
    'usit.net': false,
    'usma.net': false,
    'usmc.net': false,
    'uswestmail.net': false,
    'uu.net': false,
    'uymail.com': false,
    'uyuyuy.com': false,
    'v-sexi.com': false,
    'vahoo.com': false,
    'varbizmail.com': false,
    'vcmail.com': false,
    'velnet.co.uk': false,
    'velocall.com': false,
    'verizon.net': false,
    'verizonmail.com': false,
    'veryfast.biz': false,
    'veryspeedy.net': false,
    'videotron.ca': false,
    'violinmakers.co.uk': false,
    'vip.gr': false,
    'vipmail.ru': false,
    'virgilio.it': false,
    'virgin.net': false,
    'virginmedia.com': false,
    'virtualactive.com': false,
    'virtualmail.com': false,
    'visitmail.com': false,
    'visitweb.com': false,
    'visto.com': false,
    'visualcities.com': false,
    'vivavelocity.com': false,
    'vivianhsu.net': false,
    'vjmail.com': false,
    'vjtimail.com': false,
    'vlmail.com': false,
    'vnn.vn': false,
    'voila.fr': false,
    'volcanomail.com': false,
    'voo.be': false,
    'vote-democrats.com': false,
    'vote-hillary.com': false,
    'vote-republicans.com': false,
    'vote4gop.org': false,
    'votenet.com': false,
    'vr9.com': false,
    'w3.to': false,
    'wahoye.com': false,
    'wales2000.net': false,
    'wam.co.za': false,
    'wanadoo.co.uk': false,
    'wanadoo.es': false,
    'wanadoo.fr': false,
    'warmmail.com': false,
    'warpmail.net': false,
    'warrior.hu': false,
    'waumail.com': false,
    'wbdet.com': false,
    'wearab.net': false,
    'web-mail.com.ar': false,
    'web-police.com': false,
    'web.de': false,
    'webave.com': false,
    'webcammail.com': false,
    'webcity.ca': false,
    'webdream.com': false,
    'webinbox.com': false,
    'webindia123.com': false,
    'webjump.com': false,
    'webmail.bellsouth.net': false,
    'webmail.co.yu': false,
    'webmail.co.za': false,
    'webmail.hu': false,
    'webmails.com': false,
    'webprogramming.com': false,
    'webstable.net': false,
    'webstation.com': false,
    'websurfer.co.za': false,
    'webtopmail.com': false,
    'weedmail.com': false,
    'weekmail.com': false,
    'weekonline.com': false,
    'wehshee.com': false,
    'welsh-lady.com': false,
    'whale-mail.com': false,
    'whartontx.com': false,
    'wheelweb.com': false,
    'whipmail.com': false,
    'whoever.com': false,
    'whoopymail.com': false,
    'wickedmail.com': false,
    'wideopenwest.com': false,
    'wildmail.com': false,
    'windrivers.net': false,
    'windstream.net': false,
    'wingnutz.com': false,
    'winmail.com.au': false,
    'winning.com': false,
    'witty.com': false,
    'wiz.cc': false,
    'wkbwmail.com': false,
    'woh.rr.com': false,
    'wolf-web.com': false,
    'wombles.com': false,
    'wonder-net.com': false,
    'wongfaye.com': false,
    'wooow.it': false,
    'workmail.com': false,
    'worldemail.com': false,
    'worldmailer.com': false,
    'worldnet.att.net': false,
    'wosaddict.com': false,
    'wouldilie.com': false,
    'wow.com': false,
    'wowgirl.com': false,
    'wowmail.com': false,
    'wowway.com': false,
    'wp.pl': false,
    'wptamail.com': false,
    'wrestlingpages.com': false,
    'wrexham.net': false,
    'writeme.com': false,
    'writemeback.com': false,
    'wrongmail.com': false,
    'wtvhmail.com': false,
    'wwdg.com': false,
    'www.com': false,
    'www2000.net': false,
    'wx88.net': false,
    'wxs.net': false,
    'wyrm.supernews.com': false,
    'x-mail.net': false,
    'x-networks.net': false,
    'x5g.com': false,
    'xmastime.com': false,
    'xmsg.com': false,
    'xoom.com': false,
    'xoommail.com': false,
    'xpressmail.zzn.com': false,
    'xsmail.com': false,
    'xuno.com': false,
    'xzapmail.com': false,
    'ya.ru': false,
    'yada-yada.com': false,
    'yaho.com': false,
    'yahoo.ca': false,
    'yahoo.co.id': false,
    'yahoo.co.in': false,
    'yahoo.co.jp': false,
    'yahoo.co.kr': false,
    'yahoo.co.nz': false,
    'yahoo.co.uk': false,
    'yahoo.com': false,
    'yahoo.com.ar': false,
    'yahoo.com.au': false,
    'yahoo.com.br': false,
    'yahoo.com.cn': false,
    'yahoo.com.hk': false,
    'yahoo.com.is': false,
    'yahoo.com.mx': false,
    'yahoo.com.ph': false,
    'yahoo.com.ru': false,
    'yahoo.com.sg': false,
    'yahoo.de': false,
    'yahoo.dk': false,
    'yahoo.es': false,
    'yahoo.fr': false,
    'yahoo.ie': false,
    'yahoo.in': false,
    'yahoo.it': false,
    'yahoo.jp': false,
    'yahoo.ru': false,
    'yahoo.se': false,
    'yahoofs.com': false,
    'yalla.com': false,
    'yalla.com.lb': false,
    'yalook.com': false,
    'yam.com': false,
    'yandex.com': false,
    'yandex.ru': false,
    'yapost.com': false,
    'yawmail.com': false,
    'yclub.com': false,
    'yebox.com': false,
    'yehaa.com': false,
    'yehey.com': false,
    'yemenmail.com': false,
    'yepmail.net': false,
    'yesbox.net': false,
    'ygm.com': false,
    'yifan.net': false,
    'ymail.com': false,
    'ynnmail.com': false,
    'yogotemail.com': false,
    'yopolis.com': false,
    'youareadork.com': false,
    'youpy.com': false,
    'your-house.com': false,
    'yourinbox.com': false,
    'yourlover.net': false,
    'yourname.ddns.org': false,
    'yourname.freeservers.com': false,
    'yournightmare.com': false,
    'yours.com': false,
    'yourssincerely.com': false,
    'yoursubdomain.findhere.com': false,
    'yoursubdomain.zzn.com': false,
    'yourteacher.net': false,
    'yourwap.com': false,
    'youvegotmail.net': false,
    'yuuhuu.net': false,
    'yyhmail.com': false,
    'zahadum.com': false,
    'zcities.com': false,
    'zdnetmail.com': false,
    'zeeks.com': false,
    'zeepost.nl': false,
    'zensearch.net': false,
    'zhaowei.net': false,
    'zionweb.org': false,
    'zip.net': false,
    'zipido.com': false,
    'ziplink.net': false,
    'ziplip.com': false,
    'zipmail.com': false,
    'zipmail.com.br': false,
    'zipmax.com': false,
    'zmail.ru': false,
    'zoho.com': false,
    'zonnet.nl': false,
    'zoominternet.net': false,
    'zubee.com': false,
    'zuvio.com': false,
    'zuzzurello.com': false,
    'zwallet.com': false,
    'zybermail.com': false,
    'zydecofan.com': false,
    'zzn.com': false,
    'zzom.co.uk': false
};

